import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const AUTOMATIONS_URL = getBaseUrl('/automations/');

export async function getAutomations(page) {
    return await appRequest.doGetRequest(`${AUTOMATIONS_URL}?page=${page}`);  // { count, rows }
}

export async function getAutomationsBySymbol(symbol) {
    return await appRequest.doGetRequest(`${AUTOMATIONS_URL}?symbol=${symbol}`);
}

export async function getAllAutomations(symbol, quote, onlyActives) {
    const params = {};
    if (symbol) params.symbol = symbol;
    if (quote) params.quote = quote;
    if (onlyActives) params.onlyActives = onlyActives;

    const requestParams = new URLSearchParams(params).toString();

    return await appRequest.doGetRequest(`${AUTOMATIONS_URL}all/?${requestParams}`);
}

export async function saveAutomation(id, newAutomation) {
    if (id)
        return await appRequest.doPatchRequest(`${AUTOMATIONS_URL}${id}`, newAutomation);
    else
        return await appRequest.doPostRequest(AUTOMATIONS_URL, newAutomation);
}

export async function saveGrid(id, newAutomation, levels, quantity) {
    const gridParameters = `?levels=${levels}&quantity=${quantity}`;
    if (id)
        return await appRequest.doPatchRequest(`${AUTOMATIONS_URL}${id}${gridParameters}`, newAutomation);
    else
        return await appRequest.doPostRequest(`${AUTOMATIONS_URL}${gridParameters}`, newAutomation);
}

export async function startAutomation(id) {
    return await appRequest.doPostRequest(`${AUTOMATIONS_URL}${id}/start`, {});
}

export async function stopAutomation(id) {
    return await appRequest.doPostRequest(`${AUTOMATIONS_URL}${id}/stop`, {});
}

export async function deleteAutomation(id) {    
    return await appRequest.doDeleteRequest(`${AUTOMATIONS_URL}${id}`);
}

export async function doBacktest(newBacktest) {
    delete newBacktest.results;
    newBacktest.startTime = new Date(newBacktest.startTime).getTime();
    newBacktest.endTime = new Date(newBacktest.endTime).getTime();
    return await appRequest.doPostRequest(`${AUTOMATIONS_URL}backtest`, newBacktest);
}
