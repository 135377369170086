const DATE_LOCALE = 'en-GB'; // en-US ou pt-BR
const AMERICAN_DATE_MASK = 'mm/dd/yyyy';
const BRAZILIAN_DATE_MASK = 'dd/mm/yyyy';
const DATE_MASK = BRAZILIAN_DATE_MASK;

const DAY_MODE = 'D';
const WEEK_MODE = 'W';
const MONTH_MODE = 'M';

const WEEK_DAYS = 7;
const MONTH_DAYS = 30;

function getDateFormatted(date) {
    // return Intl.DateTimeFormat('en-GB').format(date);
    return new Intl.DateTimeFormat(DATE_LOCALE).format(date); // ou pt-BR se falhar brasileiro   
}

function getCurrentTime() {
    return new Date().toLocaleDateString('en-US');
}

function getDateFormattedShort(date) {
    const options = { 
        dateStyle: 'short',
        timeStyle: 'short'
    };
    return Intl.DateTimeFormat(DATE_LOCALE, options).format(date);
}

function getDateFormattedMedium(date) {
    const options = { 
        dateStyle: 'medium',
        timeStyle: 'medium'
    };
    return Intl.DateTimeFormat(DATE_LOCALE, options).format(date);
}

function getDateFormattedFull(date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
}

function getDateFormattedComplete(date) {
    const options = {
        year: 'numeric',
        month: 'numeric',        
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
        hour24: true
    };

    return Intl.DateTimeFormat(DATE_LOCALE, options).format(date);
}

// function getDaysInMilliseconds(days) {
//     return days * 24 * 60 * 60 * 1000;
// }

// function setTime(date, hours, minutes, seconds, milliSeconds) {
//     date.setHours(hours);
//     date.setMinutes(minutes);
//     date.setSeconds(seconds);
//     date.setMilliseconds(milliSeconds);
//     return date;
// }

function setTimeStartDate(date) {
    // date.setUTCHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date;
}

function setTimeEndDate(date) {
    // date.setUTCHours(23, 59, 59, 999);
    date.setHours(23, 59, 59, 999);
    return date;
}

function getToday(minusDays) {
    let date = new Date();
    if (minusDays)
        // date.setUTCDate(date.getUTCDate() - minusDays);
        date.setDate(date.getDate() - minusDays);
    date = setTimeStartDate(date);
    return date.getTime();
}

function getYesterday() {
    return getToday(1);
}

function getTodayStart() {
    return getToday();
}

function getTodayEnd() {
    let date = new Date();
    date = setTimeEndDate(date);
    return date.getTime();
}

function getSevenDaysAgo() {
    return getToday(WEEK_DAYS);
}

function getThirtyDaysAgo() {
    return getToday(MONTH_DAYS);
}

function initDatePicker(element) {
    new window.Datepicker(element, {
        buttonClass: 'btn',
        todayBtn: true,
        format: DATE_MASK,
        onClose: function(selectedDate) {
            console.log('onClose');
            console.log(selectedDate)
        }
    });
}

function parseDatePickerDate(str, isStartDate) {
    const split = str.split('/');
    const dateMask = DATE_MASK === AMERICAN_DATE_MASK ? `${split[0]}/${split[1]}/${split[2]}` : `${split[1]}/${split[0]}/${split[2]}`;
    let date = new Date(dateMask);
    date = (isStartDate ? setTimeStartDate(date) : setTimeEndDate(date));
    return date;
}

function mountDatePickerDateFromTimestamp(timestamp) {
    const date = timestamp ? new Date(timestamp) : new Date();
    return mountDatePickerDateFromDate(date);
}

function mountDatePickerDateFromDate(date) {
    return getDateFormatted(date);
}

// function getInicialStartTimestamp() {
//     return Date.now() - getDaysInMilliseconds(MONTH_DAYS);
// }

// function getInicialEndTimestamp() {
//     const date = setTimeEndDate(new Date());
//     return date.getTime();
// }

function getStartDate(dateMode) {
    switch (dateMode) {
        case DAY_MODE: return getTodayStart();
        case WEEK_MODE: return getSevenDaysAgo();
        case MONTH_MODE: return getThirtyDaysAgo();
        default: return getTodayStart();
    }
}

function getInicialStartDate(dateMode) {
    // const date = new Date(getInicialStartTimestamp());
    const date = new Date(getStartDate(dateMode));
    return setTimeStartDate(date);
}

// function getInicialStartDateDayMode() {
//     const date = new Date(getInicialEndTimestamp());
//     return setTimeStartDate(date);
// }

function getInicialEndDate() {
    // const date = new Date(getInicialEndTimestamp());
    // return setTimeEndDate(date);
    return new Date(getTodayEnd());
}

function getInitialStartDatePickerDate(dateMode) {
    // return mountDatePickerDateFromTimestamp(getInicialStartTimestamp());
    return mountDatePickerDateFromTimestamp(getInicialStartDate(dateMode));
}

// function getInitialStartDatePickerDateDayMode() {
//     return getInitialEndDatePickerDate();
// }

function getInitialEndDatePickerDate() {
    return mountDatePickerDateFromTimestamp(getInicialEndDate());
}

function datesAreOnSameDay(first, second) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();  
}

function isValidDate(date) {
    var timestamp = Date.parse(date);
    return (isNaN(timestamp) === false);
}

module.exports = {
    getDateFormatted,
    getDateFormattedShort,
    getDateFormattedMedium,
    getDateFormattedFull,
    getDateFormattedComplete,

    getToday,
    getYesterday,

    getTodayStart,
    getTodayEnd,
    getSevenDaysAgo,
    getThirtyDaysAgo,

    // getDaysInMilliseconds,

    // setTimeStartDate,
    // setTimeEndDate,
    initDatePicker,
    parseDatePickerDate,
    // mountDatePickerDateFromTimestamp,
    mountDatePickerDateFromDate,
    // getInicialStartTimestamp,
    // getInicialEndTimestamp,
    getInicialStartDate,
    // getInicialStartDateDayMode,
    getInicialEndDate,
    getInitialStartDatePickerDate,
    // getInitialStartDatePickerDateDayMode,
    getInitialEndDatePickerDate,

    datesAreOnSameDay,

    getCurrentTime,

    isValidDate,

    DAY_MODE,
    WEEK_MODE,
    MONTH_MODE

}
