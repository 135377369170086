import React, { useState, useEffect, useRef } from 'react';
import { getEventObject } from '../../../utils/EventUtils';
import { getValueOrDefault } from '../../../utils/ModalUtils';

/**
 * props:
 * - id
 * - text
 * - indexes
 * - price
 * - multiplier
 * - onChange
 */
function PriceTemplate(props) {

    const [priceTemplate, setPriceTemplate] = useState({ price: '', multiplier: '' });

    const priceRef = useRef('');

    const [indexes, setIndexes] = useState([]);

    useEffect(() => {
        if (!props.price) return;

        const split = props.price.replace("MEMORY['", "").replace("']", "").split(':');
        const simplePrice = split.length > 1 ? split[1] : split[0];
        setPriceTemplate({ price: simplePrice, multiplier: props.multiplier });

    }, [props.price, props.multiplier]);

    useEffect(() => {
        setIndexes(props.indexes);
    }, [props.indexes]);

    function onPriceChange(event) {
        const value = event.target.value;
        if (parseFloat(value)) return props.onChange(event);

        const index = props.indexes.find(ix => ix.variable === value);
        if (!index) return props.onChange(event);

        props.onChange(getEventObject(props.id, index.eval));
    }

    function clearPrice(event) {
        priceTemplate.price = '';
        priceRef.current.value = '';
    }

    return (
        <div className='form-group'>
            <label htmlFor={props.id}>{props.text} <span data-bs-toggle="tootip" data-bs-placement='top' title='Specify a price or choose an index. Multiplying by 1 = 100%.' className='badge bg-warning py-1'>?</span></label>
            <div className='input-group'>
                <input ref={priceRef} type='text' id={props.id} list='variables' className='form-control w-50' onChange={onPriceChange} placeholder='0' value={getValueOrDefault(priceTemplate.price)} />
                <span className='input-group-text bg-secondary' onClick={clearPrice} title='Click to clear price'>X</span>
                <input id={props.id + 'Multiplier'} type='number' className='form-control' onChange={props.onChange} placeholder='1' value={getValueOrDefault(priceTemplate.multiplier)} />
                <datalist id='variables'>
                    {
                        indexes && Array.isArray(indexes)
                            ? (
                                indexes.map(item => (
                                    <option key={`${item.symbol}:${item.variable}`}>{item.variable}</option>
                                ))
                            )
                            : (<option>NO INDEXES</option>)
                    }
                </datalist>
            </div>
        </div>
    )
}

export default PriceTemplate;