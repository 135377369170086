const { orderTypes, orderSides } = require("./OrderUtils");
const { DEFAULT_SYMBOL } = require("./SymbolUtils");

const DEFAULT_ORDER_TEMPLATE = {
    name: '',
    symbol: DEFAULT_SYMBOL,
    type: orderTypes.MARKET,
    side: orderSides.BUY,
    limitPrice: '',
    limitPriceMultiplier: 1,
    stopPrice: '',
    stopPriceMultiplier: 1,
    quantity: '',
    quantityMultiplier: 1,
    icebergQty: '',
    icebergQtyMultiplier: 1
};

module.exports = {
    DEFAULT_ORDER_TEMPLATE
}