import React, { useState, useEffect } from 'react';
import SelectQuote from '../../../components/SelectQuote/SelectQuote';
import { filterSymbolNames, getDefaultQuote } from '../../../utils/QuoteUtils';
import { getSymbols } from '../../../services/SymbolsService';
import { processError } from '../../../services/ServiceUtils';
import '../Dashboard.css';
import BookRow from './BookRow';
import { getStoredValue, setStoredValue } from '../../../utils/StorageUtils';
import { formatNumber } from '../../../utils/NumberUtils';

/**
 * props:
 * - data
 * - highestGap
 */
function Book(props) {

    const [symbols, setSymbols] = useState([]);

    const [quote, setQuote] = useState(loadQuote());

    const [filterByGap, setFilterByGap] = useState(false);

    function loadQuote() {
        return getStoredValue('bookTickerQuote', getDefaultQuote());
    }

    function saveQuote(value) {
        setStoredValue('bookTickerQuote', value);
    }

    useEffect(() => {
        getSymbols(false)
            .then(symbols => {
                // console.log(symbols);
                setSymbols(filterSymbolNames(symbols.rows, quote));
            })
            .catch(err => processError(err))
    }, [quote])

    function onQuoteChange(event) {
        saveQuote(event.target.value);
        setQuote(event.target.value);
    }

    function mustShowRow(data) {
        return !filterByGap || (data && data.orderGap > 10);
    }

    function onChangeFilter(event) {
        setFilterByGap(!filterByGap);
    }

    if (!props || !props.data) return (<React.Fragment></React.Fragment>);

    return (
        <React.Fragment>
            <div className="col-sm-12 col-md-6 mb-4">
                <div className="car border-0 shadow">
                    <div className="card-header">
                        <div className="row">
                            <div className="col">
                                <h2 className="fs-5 fw-bold mb-0">Book</h2>
                            </div>
                            <div className="col offset-md-3">
                                <SelectQuote value={quote} onChange={onQuoteChange} />
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive divScroll">
                    <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-bottom col-2" scope="col">SYMBOL</th>
                                <th className="border-bottom col-2 text-center" scope="col">BID</th>
                                <th className="border-bottom col-2 text-center" scope="col">ASK</th>
                                <th className="border-bottom col-2 text-center" scope="col"><span className={'btn btn-primary btn-sm ' + (filterByGap ? 'text-warning' : '')} role='button' title='Click to apply filter Order Gap > 10' onClick={onChangeFilter}>GAP</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                // Array.isArray(symbols) && JSON.stringify(symbols)
                                Array.isArray(symbols) && 
                                    (filterByGap ? symbols.sort((a,b) => props.data[a] && props.data[b] ? props.data[b].orderGap - props.data[a].orderGap : 0) : symbols).map(item => 
                                    mustShowRow(props.data[item])
                                    ? (<BookRow key={item} symbol={item} data={props.data[item]} />)
                                    : <React.Fragment></React.Fragment>
                                )
                            }
                        </tbody>
                        {
                            props.highestGap && props.highestGap.symbol
                            ? (
                                <tfoot>
                                <tr>
                                    <td>Highest Price Gap: {props.highestGap.symbol + ' = ' + (props.highestGap.orderGap ? formatNumber(props.highestGap.orderGap, 3) : '')}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>    
                            )
                            : <React.Fragment></React.Fragment>
                        }
                    </table>
                </div>

                </div>

            </div>
        </React.Fragment>
    )
}

export default Book;