export function getEnvironment() {
    return localStorage.getItem("environment");
}

export function setEnvironment(environment) {
    localStorage.setItem('environment', environment);
}

export function removeEnvironment() {
    localStorage.removeItem('environment');
}

export function getUserName() {
    return localStorage.getItem("userName");
}

export function setUserName(userName) {
    localStorage.setItem('userName', userName);
}

export function removeUserName() {
    localStorage.removeItem('userName');
}


