
const orderStatus = {
    FILLED: 'FILLED',
    PARTIALLY_FILLED: 'PARTIALLY_FILLED',
    CANCELED: 'CANCELED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    NEW: 'NEW'  
}

const orderTypes = {
    STOP_LOSS: 'STOP_LOSS',
    STOP_LOSS_LIMIT: 'STOP_LOSS_LIMIT',
    TAKE_PROFIT: 'TAKE_PROFIT',
    TAKE_PROFIT_LIMIT: 'TAKE_PROFIT_LIMIT',
    MARKET: 'MARKET',
    LIMIT: 'LIMIT',
    ICEBERG: 'ICEBERG',
    TRAILING_STOP: 'TRAILING_STOP'  
}

const orderTypesSelect = {
    MARKET: 'Market',
    TRAILING_STOP: 'Trailing Stop',
    LIMIT: 'Limit',
    STOP_LOSS_LIMIT: 'Stop Loss Limit',
    TAKE_PROFIT_LIMIT: 'Take Profit Limit',
    ICEBERG: 'Iceberg'

    // Binance abandonou
    // STOP_LOSS: 'Stop Loss',
    // TAKE_PROFIT: 'Take Profit',
}

const orderSides = {
    BUY: 'BUY',
    SELL: 'SELL'
}

const orderSidesSelect = {
    BUY: 'Buy',
    SELL: 'Sell'
}

function isBuyOrder(order) {
    return order.side === orderSides.BUY;
}

function isSellOrder(order) {
    return order.side === orderSides.SELL;
}

const STOP_TYPES = [orderTypes.STOP_LOSS, orderTypes.STOP_LOSS_LIMIT, orderTypes.TAKE_PROFIT, orderTypes.TAKE_PROFIT_LIMIT];

const LIMIT_TYPES = [orderTypes.LIMIT, orderTypes.STOP_LOSS_LIMIT, orderTypes.TAKE_PROFIT_LIMIT];

const FINISHED_STATUS = [orderStatus.FILLED, orderStatus.REJECTED, orderStatus.CANCELED];

function calcTrailingStop(side, limitPrice, stopPriceMultiplier) {
    const callbackRate = stopPriceMultiplier / 100;
    return side === orderSides.BUY
        ? limitPrice * (1 + callbackRate)
        : limitPrice * (1 - callbackRate);
}

module.exports = {
    orderStatus,
    orderTypes,
    orderTypesSelect,
    orderSides,
    orderSidesSelect,
    STOP_TYPES,
    LIMIT_TYPES,
    FINISHED_STATUS,
    isBuyOrder,
    isSellOrder,
    calcTrailingStop
}