export const fiatList = {
    USD: 'USD',
    BRL: 'BRL',
    EUR: 'EUR',
    GBP: 'GBP',
    AUD: 'AUD',
    NGN: 'NGN',
    TRY: 'TRY',
    UAH: 'UAH'
}

export const DEFAULT_FIAT_PROPERTY = "defaultFiat";
export const DEFAULT_FIAT = "USD";

export function getDefaultFiat() {
    const value = localStorage.getItem(DEFAULT_FIAT_PROPERTY);
    return value ? value : DEFAULT_FIAT;
}

export function setDefaultFiat(quote) {
    localStorage.setItem(DEFAULT_FIAT_PROPERTY, quote);
}

