const intervalTypes = {
    INT_1m : '1m',
    INT_3m : '3m',
    INT_5m : '5m',
    INT_15m: '15m',
    INT_30m: '30m',
    INT_1h : '1h',
    INT_2h : '2h',
    INT_4h : '4h',
    INT_6h : '6h',
    INT_8h : '8h',
    INT_12h: '12h',
    INT_1d : '1d',
    INT_3d : '3d',
    INT_1w : '1w',
    INT_1M : '1M'
}

const intervalTypesSelect = {
    INT_1m : '1 minute',
    INT_3m : '3 minutes',
    INT_5m : '5 minutes',
    INT_15m: '15 minutes',
    INT_30m: '30 minutes',
    INT_1h : '1 hour',
    INT_2h : '2 hours',
    INT_4h : '4 hours',
    INT_6h : '6 hours',
    INT_8h : '8 hours',
    INT_12h: '12 hours',
    INT_1d : '1 day',
    INT_3d : '1 days',
    INT_1w : '1 week',
    INT_1M : '1 month'
}

module.exports = {
    intervalTypes,
    intervalTypesSelect
}
