import React from "react";

/**
 * props:
 * - onClick
 */
function NewAutomationButton(props) {
    return (
        <React.Fragment>
            <button id='btnNewAutomation' className='btn btn-primary dropdown-toggle' data-bs-toggle='dropdown'>
                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
                New Automation
            </button>
            <ul className="dropdown-menu" aria-labelledby="btnNewAutomation">
                <li>
                    <a id="linkRegular" className="dropdown-item" href="/#" data-bs-toggle='modal' data-bs-target='#modalAutomation' onClick={props.onClick}>
                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clipRule="evenodd" /></svg>
                        Regular
                    </a>
                </li>
                <li>
                    <a id="linkGrid" className="dropdown-item" href="/#" data-bs-toggle='modal' data-bs-target='#modalGrid' onClick={props.onClick}>
                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" /></svg>
                        Grid
                    </a>
                </li>
                <li>
                    <a id="linkSchedule" className="dropdown-item" href="/#" data-bs-toggle='modal' data-bs-target='#modalAutomation' onClick={props.onClick}>
                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" /></svg>
                        Scheduled
                    </a>
                </li>
            </ul>
        </React.Fragment>
    )
}

export default NewAutomationButton;