import React, { useEffect, useRef, useState } from 'react';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import SelectSymbol from '../../../components/SelectSymbol/SelectSymbol';
import ConditionsArea from './ConditionsArea/ConditionsArea';
import ActionsArea from './ActionsArea/ActionsArea';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import { saveAutomation } from '../../../services/AutomationsService';
import { processError } from '../../../services/ServiceUtils';
import { getIndexes } from '../../../services/BeholderService';
import { isScheduleAutomation } from '../../../utils/AutomationUtils';
import { getSymbol } from '../../../services/SymbolsService';
import '../Automations.css';
import ScheduleArea from './ScheduleArea/ScheduleArea';
import LogButton from '../../../components/Logs/LogButton';
import LogView from '../../../components/Logs/LogView';
import { DEFAULT_SYMBOL, getQuoteFromWildcard, isWildcard, getWildcardSymbolObject } from '../../../utils/SymbolUtils';
import { indexKeys } from '../../../utils/IndexUtils';
import { initializeModal, getValueOrDefault } from '../../../utils/ModalUtils';

/**
 * props:
 * - data
 * - onSubmit
 */
function AutomationModal(props) {
    const btnClose = useRef('');
    const btnSave = useRef('');

    const DEFAULT_AUTOMATION = {
        id: 0,
        symbol: DEFAULT_SYMBOL,
        conditions: '',
        name: '',
        indexes: '',
        actions: []
    };

    const [indexes, setIndexes] = useState([]);
    const [symbol, setSymbol] = useState({});
    const [error, setError] = useState('');
    const [automation, setAutomation] = useState(DEFAULT_AUTOMATION);

    function onSubmitClick(event) {
        saveAutomation(automation.id, automation)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)));
    }

    function onInputChange(event) {
        setAutomation(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        if (!props.data) return;
        setAutomation(props.data);
        setShowLogs(false);
    }, [props.data]);

    function getIndexObjects(automationSymbol, indexName, propertyNames) {
        return propertyNames.map(property => {
            return {
                variable: `${indexName}.${property}`,
                symbol: automationSymbol,
                eval: `MEMORY[${automationSymbol}:${indexName}].current.${property}`,
                example: 0
            }
        });
    }

    function getDefaultIndexes(automationSymbol) {
        let result = [];
        result = result.concat(
            getIndexObjects(automationSymbol, 'BOOK', [
                'bestAsk', 
                'bestBid'
            ])
        );
        result = result.concat(
            getIndexObjects(automationSymbol, 'TICKER', [
                'averagePrice', 
                'bestAsk', 
                'bestBid', 
                'bestAskQty', 
                'bestBidQty', 
                'close', 
                'open', 
                'low', 
                'high', 
                'closeQty', 
                'percentChange', 
                'prevClose', 
                'priceChange', 
                'quoteVolume', 
                'volume'
            ])
        );
        return result;
    }

    useEffect(() => {
        if (!automation || !automation.symbol) return;

        const automationSymbol = automation.symbol.toUpperCase();

        const isWildcardSymbol = isWildcard(automationSymbol);

        if (isWildcardSymbol)
            setSymbol(getWildcardSymbolObject(automationSymbol));
        else {
            getSymbol(automationSymbol)
                .then(symbolObj => {
                    if (symbolObj)
                        setSymbol(symbolObj);
                    else
                        setIndexes(getDefaultIndexes(automationSymbol))
                })
                .catch(err => setError(processError(err)));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automation.symbol]);

    useEffect(() => {
        if (!symbol || !symbol.base) return;

        getIndexes()
            .then(indexes => {
                const isWildcard = symbol.base === '*';
                let filteredIndexes = isWildcard
                    ? indexes.filter(k => k.symbol.endsWith(symbol.quote))
                    : indexes.filter(k => k.symbol === automation.symbol);

                if (isWildcard) {
                    filteredIndexes.forEach(ix => {
                        if (ix.variable.startsWith(indexKeys.WALLET)) {
                            ix.symbol = getQuoteFromWildcard(ix.symbol);
                            ix.eval = getQuoteFromWildcard(ix.eval);
                        }
                        else {
                            ix.eval = ix.eval.replace(ix.symbol, automation.symbol);
                            ix.symbol = automation.symbol;
                        }
                    })
                }
                else {
                    const baseWallet = indexes.find(ix => ix.variable.startsWith(indexKeys.WALLET) && symbol.base === ix.symbol);
                    if (baseWallet) filteredIndexes.splice(0, 0, baseWallet);

                    const quoteWallet = indexes.find(ix => ix.variable.startsWith(indexKeys.WALLET) && symbol.quote === getQuoteFromWildcard(ix.symbol));
                    if (quoteWallet) filteredIndexes.splice(0, 0, quoteWallet);
                }

                filteredIndexes = filteredIndexes.filter((item, index, self) =>
                    index === self.findIndex(t => t.eval === item.eval)
                )

                setIndexes(filteredIndexes);
            })
            .catch(err => setError(processError(err)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbol])

    const [showLogs, setShowLogs] = useState(false);
    function onLogClick(event) {
        setShowLogs(!showLogs);
    }

    // eslint-disable-next-line no-unused-vars
    // const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        initializeModal('modalAutomation',
            () => {
                // setIsVisible(false);
                setAutomation({ ...DEFAULT_AUTOMATION });
            },
            () => {
                // setIsVisible(true);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='modal fade' id='modalAutomation' tabIndex='-1' role='dialog' aria-labelledby='modalTitleNotify' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered' role='document'>
                <div className='modal-content'>
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}{isScheduleAutomation(props.data) ? 'Scheduled ' : 'Regular '}Automation</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-7 mb-3'>
                                    <label htmlFor='symbol'>Symbol:</label>
                                    <SelectSymbol onChange={onInputChange} symbol={automation.symbol} disabled={automation.id > 0} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <label htmlFor='symbol'>Name:</label>
                                    <input type='text' className='form-control' id='name' placeholder='Automation name' value={getValueOrDefault(automation.name)} required={true} onChange={onInputChange} />
                                </div>
                            </div>
                        </div>
                        {
                            !showLogs && isScheduleAutomation(automation)
                                ? <ScheduleArea schedule={automation.schedule} onChange={onInputChange} />
                                : <React.Fragment></React.Fragment>
                        }
                        {
                            showLogs
                                ? <LogView file={'A_' + automation.id} />
                                : (
                                    <React.Fragment>
                                        <ul className="nav nav-tabs" id="tabs" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="conditions-tab" data-bs-toggle="tab" data-bs-target="#conditions" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                    Conditions
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="actions-tab" data-bs-toggle="tab" data-bs-target="#actions" type="button" role="tab" aria-controls="actions" aria-selected="false">
                                                    Actions
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="tab-content px-3 mb-3" id="tabContent">
                                            <div className="tab-pane fade show active pt-3" id="conditions" role="tabpanel" aria-labelledby="conditions-tab">
                                                <ConditionsArea indexes={indexes} conditions={automation.conditions} onChange={onInputChange} symbol={automation.symbol} />
                                            </div>
                                            <div className="tab-pane fade" id="actions" role="tabpanel" aria-labelledby="actions-tab">
                                                <ActionsArea symbol={automation.symbol} actions={automation.actions} onChange={onInputChange} />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                <div className='form-group'>
                                                    <SwitchInput id='isActive' text='Is Active?' onChange={onInputChange} isChecked={automation.isActive} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <div className='form-group'>
                                                    <SwitchInput id='logs' text='Enable Logs?' onChange={onInputChange} isChecked={automation.logs} />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                        }
                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <LogButton id={automation.id} onClick={onLogClick} />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmitClick}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                            Save
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AutomationModal;