import axios from './BaseService';

/*
import { getAuthHeaders } from './ServiceUtils';

function getHeaders(authToken) {
    return authToken ? getAuthHeaders(authToken) : undefined;
}

async function doGetRequest(url, authToken) {
    const headers = getHeaders(authToken);
    const response = await axios.get(url, headers);
    return response.data;
}

async function doPostRequest(url, data, authToken) {
    const headers = getHeaders(authToken);
    const response = await axios.post(url, data, headers);
    return response.data;
}

async function doPatchRequest(url, data, authToken) {
    const headers = getHeaders(authToken);
    const response = await axios.patch(url, data, headers);
    return response.data;
}

async function doDeleteRequest(url, authToken) {
    const headers = getHeaders(authToken);
    const response = await axios.delete(url, headers);
    return response.data;
}
*/

async function doGetRequest(url) {
    const response = await axios.get(url);
    return response.data;
}

async function doPostRequest(url, data) {
    const response = await axios.post(url, data);
    return response.data;
}

async function doPatchRequest(url, data) {
    const response = await axios.patch(url, data);
    return response.data;
}

async function doDeleteRequest(url) {
    const response = await axios.delete(url);
    return response.data;
}

const appRequest = {
    doGetRequest,
    doPostRequest,
    doPatchRequest,
    doDeleteRequest
};

export default appRequest;