import React, { useState, useEffect } from 'react';
import { getMemoryIndex } from '../../../services/BeholderService';
import { indexKeys } from '../../../utils/IndexUtils';
import { formatNumber } from '../../../utils/NumberUtils';
import { isSellOrder } from '../../../utils/OrderUtils';

/**
 * props:
 * - grids
 * - symbol
 */
function GridLineChart(props) {

    const SELL_SERIE_CLASS = 'ct-series-c';
    const VALUE_SERIE_CLASS = 'ct-series-g';
    const BUY_SERIE_CLASS = 'ct-series-e';

    // eslint-disable-next-line no-unused-vars
    const [lineChart, setLineChart] = useState({});
    const [labelValueList, setLabelValueList] = useState([]);

    const [gridValues, setGridValues] = useState([]);

    const [currentValue, setCurrentValue] = useState('');
    const [nextUpperValue, setNextUpperValue] = useState('');
    const [nextLowerValue, setNextLowerValue] = useState('');

    async function loadCurrentValue() {
        const book = await getMemoryIndex(props.symbol, indexKeys.BOOK, '');
        if (!book) return false;

        let avgValue = (book.current.bestBid + book.current.bestAsk) / 2;
        avgValue = avgValue.toFixed(0);
        setCurrentValue(avgValue);
        return avgValue;
    }

    async function update(i) {
        try {
            const avgValue = await loadCurrentValue();
            if (avgValue === false) return; 

            const labelValueListAux = labelValueList;
            labelValueListAux.push({
                label: null,
                value: avgValue
            });

            if (labelValueList.length > 10)
                labelValueList.shift();
            setLabelValueList(labelValueListAux);
            updateChart();
        }
        catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        let nextUpperValueAux = null;
        let nextLowerValueAux = null;
        gridValues.forEach(g => {
            if (g.value > currentValue && (nextUpperValueAux == null || g.value < nextUpperValueAux))
                nextUpperValueAux = g.value;
            if (g.value < currentValue && (nextLowerValueAux == null || g.value > nextLowerValueAux))
                nextLowerValueAux = g.value;
        });
        
        setNextUpperValue(nextUpperValueAux);
        setNextLowerValue(nextLowerValueAux);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

    useEffect(() => {
        let i = 0;
        const timer = setInterval(() => {
            i++;
            update(i);
        }, 1000);
        return () => {
            clearInterval(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridValues]);

    useEffect(() => {
        if (!props.grids) {
            setGridValues([]);
            return;
        }

        const values = [];
        props.grids.forEach(g => {
            values.push({
                value: parseFloat(getItem(g.conditions)),
                className: (isSellOrder(g.orderTemplate) ? SELL_SERIE_CLASS : BUY_SERIE_CLASS)
            });
        });
        setGridValues(values);
    }, [props.grids]);

    function getItem(conditions) {
        return conditions.split(' && ')[0].split(/[><]/)[1];
    }

    function updateChart() {
        if (!props.grids) return;
        if (!labelValueList) return;

        const series = [];
        const totalItems = labelValueList.length;
        gridValues.forEach(g => {
            series.push({
                data: new Array(totalItems).fill(g.value),
                className: g.className
            });
        });

        const labels = labelValueList.map(i => i.label);
        const values = labelValueList.map(i => i.value);
        series.push({
            data: values,
            className: VALUE_SERIE_CLASS
        });

        const chart = new window.Chartist.Line('.ct-beholder-chart-grid-line', {
            labels: labels,
            series: series
        }, {
            showArea: false,
            fullWidth: true,
            showPoint: false,
            chartPadding: { right: 20 },
            axisX: {
                showGrid: true,
                showLabel: false
            },
            axisY: {
                showGrid: true,
                showLabel: true
            }
        });
        setLineChart(chart);
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-12 mb-4'>
                    <div className='card bg-yellow-100 border-0 shadow'>
                        <div className='card-header d-sm-flex flex-row align-item-center flex-0'>
                            <div className='d-block mb-3 mb-sm-0'>
                                <h2 className='fs-6 fw-extrabold'>{'Next upper: ' + (nextUpperValue ? formatNumber(nextUpperValue) : '')}</h2>
                                <h2 className='fs-6 fw-extrabold'>{'Current value: ' + (currentValue ? formatNumber(currentValue) : '')}</h2>
                                <h2 className='fs-6 fw-extrabold'>{'Next lower: ' + (nextLowerValue ? formatNumber(nextLowerValue) : '')}</h2>
                            </div>
                        </div>
                        <div className='card-body p-2'>
                            <div className='ct-beholder-chart-grid-line ct-double-octave' style={{ minHeight: "320px" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GridLineChart;