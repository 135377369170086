const { DEFAULT_SYMBOL } = require("./SymbolUtils");

const shareTypes = {
    EVERYONE: 'everyone',
    NONE: 'none'
};

const DEFAULT_STRATEGY = {
    id: null,
    name: '',
    symbol: DEFAULT_SYMBOL,
    userId: 0,
    buyAutomationId: 0,
    sellAutomationId: 0,
    monitorId: 0,
    isActive: false,
    startedAt: '',
    sharedWith: shareTypes.NONE
};

const DEFAULT_STRATEGY_VIEW = {
    id: 0,
    name: '',
    symbol: '',
    user: {},
    buyAutomation: {},
    sellAutomation: {},
    monitor: {},
    startedAt: '',
    sharedWith: ''
};

module.exports = {
    shareTypes,
    DEFAULT_STRATEGY,
    DEFAULT_STRATEGY_VIEW
}