import React, { useEffect, useRef, useState } from 'react';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import SelectSymbol from '../../../components/SelectSymbol/SelectSymbol';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import { saveGrid } from '../../../services/AutomationsService';
import { processError } from '../../../services/ServiceUtils';
import { getMemoryIndex } from '../../../services/BeholderService';
import WalletSummary from '../../../components/WalletSummary/WalletSummary';
import SymbolPrice from '../../../components/SymbolPrice/SymbolPrice';
import '../Automations.css';
import { getSymbol } from '../../../services/SymbolsService';
import GridTable from './GridTable';
import LogButton from '../../../components/Logs/LogButton';
import LogView from '../../../components/Logs/LogView';
import GridButton from './GridButton';
import GridLineChart from './GridLineChart';
import { quantityTypeNames, quantityTypes } from '../../../utils/QuantityUtils';
import { actionTypes } from '../../../utils/ActionUtils';
import { indexKeys } from '../../../utils/IndexUtils';
import { forceAmericanDecimalSeparator, formatNumber } from '../../../utils/NumberUtils';
import { initializeModal, isModalOpen } from '../../../utils/ModalUtils';

/**
 * props:
 * - data
 * - onSubmit
 */
function GridModal(props) {

    const btnClose = useRef('');
    const btnSave = useRef('');
    const notionalPrice = useRef('');

    const upperLowerMarginInput = useRef('');
    const lowerLimitInput = useRef('');
    const upperLimitInput = useRef('');

    const MODAL_ID = 'modalGrid';

    const DEFAULT_AUTOMATION = {
        conditions: '',
        name: '',
        indexes: '',
        actions: []
    };
    const DEFAULT_GRID = {
        lowerLimit: '',
        upperLimit: '',
        levels: '',
        quantity: ''
    };

    const [gridView, setGridView] = useState(false);
    const [error, setError] = useState('');
    const [automation, setAutomation] = useState(DEFAULT_AUTOMATION);
    const [grid, setGrid] = useState(DEFAULT_GRID);

    function updateNotionalPrice(value) {
        // notionalPrice.current.value = `${value}`.substring(0, 10);
        notionalPrice.current.value = formatNumber(value);
    }

    function onGridChange(event) {
        const value = event.target.value === quantityTypeNames.MIN_NOTIONAL ? quantityTypes.MIN_NOTIONAL : forceAmericanDecimalSeparator(event.target.value);
        const fieldName = event.target.id;
        grid[fieldName] = value;

        setGrid(prevState => ({ ...prevState, [fieldName]: value }));

        if (fieldName === 'quantity' && parseFloat(grid.quantity) < parseFloat(symbol.minLotSize)) {
            setError('Min. Lot Size: ' + symbol.minLotSize);
            btnSave.current.disabled = true;
            return;
        }
        else if (fieldName === 'quantity' || fieldName === 'lowerLimit') {
            const notional = grid.lowerLimit * parseFloat(grid.quantity);
            // notionalPrice.current.value = `${notional}`.substring(0, 10);
            updateNotionalPrice(notional);
            if (notional < parseFloat(symbol.minNotional)) {
                setError(quantityTypeNames.MIN_NOTIONAL + ': ' + symbol.minNotional);
                btnSave.current.disabled = true;
                return;
            }
        }

        btnSave.current.disabled = false;
        setError('');
    }

    const [symbol, setSymbol] = useState(false);
    useEffect(() => {
        if (!automation.symbol) return;

        setError('');
        getSymbol(automation.symbol)
            .then(symbol => {
                setSymbol(symbol);
                if (grid.quantity === quantityTypeNames.MIN_NOTIONAL)
                    // notionalPrice.current.value = `${symbol.minNotional}`;
                    updateNotionalPrice(symbol.minNotional);
                else
                    // notionalPrice.current.value = `${grid.quantity * grid.lowerLimit}`.substring(0, 10);
                    updateNotionalPrice(grid.quantity * grid.lowerLimit);
            })
            .catch(err => setError(processError(err)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automation.symbol]);

    const [wallet, setWallet] = useState({
        base: {
            symbol: '',
            qty: 0
        },
        quote: {
            symbol: '',
            qty: 0
        }
    });

    async function loadWallet(symbol) {
        try {
            const baseQty = await getMemoryIndex(symbol.base, indexKeys.WALLET, '');
            const quoteQty = await getMemoryIndex(symbol.quote, indexKeys.WALLET, '');
            setWallet({
                base: { symbol: symbol.base, qty: baseQty },
                quote: { symbol: symbol.quote, qty: quoteQty }
            });
        }
        catch (err) {
            setError(processError(err));
        }
    }

    useEffect(() => {
        if (!symbol || !symbol.base) return;

        loadWallet(symbol);
    }, [symbol]);

    // isVisible: usado pra renderizar apenas quando a modal estiver sendo exibida, para evitar processamento de stream de um dos subcomponentes
    const [isVisible, setIsVisible] = useState(isModalOpen(MODAL_ID));

    useEffect(() => {
        initializeModal(MODAL_ID,
            () => {
                setIsVisible(false);
                setGridView(false);
                setAutomation({ ...DEFAULT_AUTOMATION });
                setGrid({ ...DEFAULT_GRID });
            },
            () => {
                setIsVisible(true);
                setGridView(false);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function onSubmit(event) {
        setError('');
        const quantity = grid.quantity === quantityTypeNames.MIN_NOTIONAL ? quantityTypes.MIN_NOTIONAL : grid.quantity;
        if (!quantity) return setError('Quantity is needed.');

        // Campos obrigatórios de automation mas que não estão na tela de Grid
        automation.name = `${actionTypes.GRID} ${automation.symbol} #${grid.levels}`;
        automation.actions = [{ type: actionTypes.GRID }];
        const indexName = `${automation.symbol}:${indexKeys.BOOK}`;
        automation.indexes = indexName;
        const variableBestAsk = `MEMORY['${indexName}'].current.bestAsk`;
        const variableBestBid = `MEMORY['${indexName}'].current.bestBid`;
        automation.conditions = `${variableBestAsk}>${grid.lowerLimit} && ${variableBestBid}<${grid.upperLimit}`;

        saveGrid(automation.id, automation, grid.levels, quantity)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)))
    }

    function onInputChange(event) {
        setAutomation(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        if (!props.data) return;
        setAutomation(props.data);

        if (!props.data.id) return setGrid(DEFAULT_GRID);

        const conditionSplit = props.data.conditions.split(' && ');
        if (!conditionSplit || conditionSplit.length < 2) return;

        if (!props.data.grids || !props.data.grids.length) return;

        const quantity = props.data.grids[0].orderTemplate.quantity;
        setGrid({
            lowerLimit: parseFloat(conditionSplit[0].split('>')[1]),
            upperLimit: parseFloat(conditionSplit[1].split('<')[1]),
            levels: props.data.grids.length + 1,
            quantity: quantity === quantityTypes.MIN_NOTIONAL ? quantityTypeNames.MIN_NOTIONAL : quantity
        });
        setShowLogs(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.id]);

    function onViewGridsClick(event) {
        if (!gridView) setShowLogs(false);
        setGridView(!gridView);
    }

    const [showLogs, setShowLogs] = useState(false);
    function onLogClick(event) {
        if (!showLogs) setGridView(false);
        setShowLogs(!showLogs);
    }

    async function getAverageValue() {
        try {
            const book = await getMemoryIndex(automation.symbol, indexKeys.BOOK, '');
            if (!book) return;

            let avgValue = (book.current.bestBid + book.current.bestAsk) / 2;
            avgValue = avgValue.toFixed(0);
            return avgValue;
        }
        catch (err) {
            console.error(err);
        }
    }

    function isNumeric(val) {
        return /^-?\d+$/.test(val);
    }

    async function onChangeUpperLowerMargin(event) {
        const margin = parseInt(upperLowerMarginInput.current.value);
        if (!isNumeric(margin)) return;

        const avgValue = parseInt(await getAverageValue());
        const lowerLimit = avgValue - margin;
        const upperLimit = avgValue + margin;
        setGrid(prevState => ({
            ...prevState,
            lowerLimit,
            upperLimit
        }));
    }

    return (
        <div className='modal fade' id={MODAL_ID} tabIndex='-1' role='dialog' aria-labelledby='modalTitleNotify' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered' role='document'>
                <div className='modal-content'>
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Grid Automation</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <label htmlFor='symbol'>Symbol:</label>
                                    <SelectSymbol onChange={onInputChange} symbol={automation.symbol} disabled={automation.id > 0} />
                                </div>
                                <div className='col-md-6 mb-3'>
                                    {
                                        isVisible && automation.symbol
                                            ? <SymbolPrice symbol={automation.symbol} />
                                            : <React.Fragment></React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            !gridView && !showLogs
                                ? (
                                    <React.Fragment>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <label>You have:</label>
                                                <WalletSummary wallet={wallet} symbol={symbol} />
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <label htmlFor='lowerLimit'>Upper/Lower Margin:</label>
                                                    <input ref={upperLowerMarginInput} className='form-control' type='number' id='upperLowerMargin' placeholder='0' onChange={onChangeUpperLowerMargin} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <label htmlFor='lowerLimit'>Lower Limit:</label>
                                                    <input ref={lowerLimitInput} className='form-control' type='number' id='lowerLimit' placeholder='0' value={grid.lowerLimit} onChange={onGridChange} />
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <label htmlFor='upperLimit'>Upper Limit:</label>
                                                    <input ref={upperLimitInput} className='form-control' type='number' id='upperLimit' placeholder='0' value={grid.upperLimit} onChange={onGridChange} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <label htmlFor='levels'>Levels:</label>
                                                    <input className='form-control' type='number' id='levels' placeholder='3' value={grid.levels} onChange={onGridChange} disabled={automation.id > 0} />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="quantity">Quantity:</label>
                                                        <input className="form-control" id="quantity" type="text" list="gridQtyList" placeholder={symbol.minLotSize} value={grid.quantity || ''} onChange={onGridChange} />
                                                        <datalist id="gridQtyList">
                                                            <option>{quantityTypeNames.MIN_NOTIONAL}</option>
                                                        </datalist>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <label htmlFor='notionalPrice'>Notional Price:</label>
                                                    <input ref={notionalPrice} className='form-control' type='number' id='notionalPrice' placeholder='0' disabled />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <div className='form-group'>
                                                        <SwitchInput id='isActive' text='Is Active?' onChange={onInputChange} isChecked={automation.isActive} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <div className='form-group'>
                                                        <SwitchInput id='logs' text='Enable Logs?' onChange={onInputChange} isChecked={automation.logs} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                                : <React.Fragment></React.Fragment>
                        }
                        {
                            gridView && !showLogs
                                ? (
                                    <div className='form-group' >

                                        <ul className='nav nav-tabs' id='tabs' role='tablist'>
                                            <li className='nav-link' role='presentation' style={{ padding: '0px' }}>
                                                <button style={{ padding: '5px' }} className='nav-link active' id='chart-tab' data-bs-toggle='tab' data-bs-target='#chartTab' type='button' role='tab'>
                                                    Chart
                                                </button>
                                            </li>
                                            <li className='nav-link' role='presentation' style={{ padding: '0px' }}>
                                                <button style={{ padding: '5px' }} className='nav-link' id='table-tab' data-bs-toggle='tab' data-bs-target='#tableTab' type='button' role='tab'>
                                                    Table
                                                </button>
                                            </li>
                                        </ul>
                                        <div className='tab-content px-3 mb-3' id='tabContent'>
                                            <div className='tab-pane fade show active pt-3' id='chartTab' role='tabpanel'>
                                                <GridLineChart grids={automation.grids} symbol={automation.symbol} />
                                            </div>
                                            <div className='tab-pane fade pt-3' id='tableTab' role='tabpanel'>
                                                <GridTable data={automation.grids} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                : <React.Fragment></React.Fragment>
                        }
                        {
                            showLogs
                                ? <LogView file={'A_' + automation.id} />
                                : <React.Fragment></React.Fragment>
                        }
                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <LogButton id={automation.id} onClick={onLogClick} />
                        <GridButton id={automation.id} onClick={onViewGridsClick} />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                            Save
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GridModal;