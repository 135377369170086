import React from 'react';
import RowDeleteButton from '../../components/ActionButtons/RowDeleteButton';
import RowEditButton from '../../components/ActionButtons/RowEditButton';
import RowStartButton from '../../components/ActionButtons/RowStartButton';
import RowStopButton from '../../components/ActionButtons/RowStopButton';
import AutomationIcon from '../../components/AutomationIcon/AutomationIcon';
import { isGridAutomation, isScheduleAutomation } from '../../utils/AutomationUtils';


/**
 * props:
 * - data
 * - onEditClick
 * - onStopClick
 * - onStartClick
 * - onDeleteClick
 */
function AutomationRow(props) {

    function getStatusClass(automation) {
        if (isScheduleAutomation(automation))
            return automation.isActive ? 'text-info' : 'text-danger';
        else
            return automation.isActive ? 'text-success' : 'text-danger';
    }

    function getStatusText(automation) {
        if (isScheduleAutomation(automation))
            return automation.isActive ? 'SCHEDULED' : 'STOPPED';
        else
            return automation.isActive ? 'RUNNING' : 'STOPPED';
    }

    function getEditModal() {
        if (!props.data.actions[0]) return 'modalAutomation';

        return isGridAutomation(props.data) ? 'modalGrid' : 'modalAutomation';
    }

    return (
        <tr>
            <td>
                <AutomationIcon automation={props.data} />
                {props.data.symbol}
            </td>
            <td>
                {props.data.name}
            </td>

            <td>
                <span className={getStatusClass(props.data)}>
                    {getStatusText(props.data)}
                </span>
            </td>
            <td>
                <RowEditButton id={props.data.id} title='Edit this Automation' modalId={getEditModal()} onClick={props.onEditClick} />
                <RowStopButton id={props.data.id} title='Stop this Automation' onClick={props.onStopClick} showButton={props.data.isActive} />
                <RowStartButton id={props.data.id} title='Start this Automation' onClick={props.onStartClick} showButton={!props.data.isActive} />
                <RowDeleteButton id={props.data.id} title='Delete this Automation' onClick={props.onDeleteClick} showButton={!props.data.isActive} />
            </td>
        </tr>
    );
}

export default AutomationRow;