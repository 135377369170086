const { actionTypes } = require("./ActionUtils");

const automationTypes = {
    REGULAR: 'REGULAR',
    SCHEDULED: 'SCHEDULED',
    TRAILING: 'TRAILING',
    GRID: 'GRID'
}

function getActionType(automation) {
    if (!automation || !automation.actions || !automation.actions.length) return false;
    return automation.actions[0].type;
}

function isGridAutomation(automation) {
    const actionType = getActionType(automation);
    if (actionType)
        return (actionType === actionTypes.GRID);
    else
        return automation.name.startsWith(actionTypes.GRID + ' ');
}

function isScheduleAutomation(automation) {
    // return Boolean(automation.schedule);
    // return automation.schedule;
    return (automation.schedule ? true : false);
}

function isRegularAutomation(automation) {
    return (!isGridAutomation(automation) && !isScheduleAutomation(automation));
}

function isTrailingAutomation(automation) {
    const actionType = getActionType(automation);
    if (actionType)
        return (getActionType(automation) === actionTypes.TRAILING);
    else
        return automation.name.startsWith(actionTypes.TRAILING);
}

function getAutomationType(automation) {
    if (isGridAutomation(automation))
        return automationTypes.GRID;
    else if (isTrailingAutomation(automation))
        return automationTypes.TRAILING;
    else if (isScheduleAutomation(automation))
        return automationTypes.SCHEDULED;
    else // if (isRegularAutomation(automation))
        return automationTypes.REGULAR;
}

module.exports = {
    automationTypes,
    isGridAutomation,
    isScheduleAutomation,
    isRegularAutomation,
    isTrailingAutomation,
    getAutomationType
}