import React, { useRef, useState, useEffect } from 'react';
import SelectSymbol from '../../../components/SelectSymbol/SelectSymbol';
import SelectSide from '../../../components/NewOrder/SelectSide';
import OrderType from '../../../components/NewOrder/OrderType';
import QuantityTemplate from './QuantityTemplate';
import PriceTemplate from './PriceTemplate';
import { processError } from '../../../services/ServiceUtils';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import { saveOrderTemplate } from '../../../services/OrderTemplatesService';
import { STOP_TYPES, orderTypes, calcTrailingStop } from '../../../utils/OrderUtils';
import { getIndexes } from '../../../services/BeholderService'
import TrailingTemplate from './TrailingTemplate';
import { getValueOrDefault, initializeModal } from '../../../utils/ModalUtils';
import { DEFAULT_ORDER_TEMPLATE } from '../../../utils/OrderTemplateUtils';
// import { indexKeys } from '../../../utils/IndexUtils';

/**
 * props:
 * - onSubmit
 */
function OrderTemplateModal(props) {

    const [error, setError] = useState('');
    const [indexes, setIndexes] = useState([]);

    const [orderTemplate, setOrderTemplate] = useState(DEFAULT_ORDER_TEMPLATE);

    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        saveOrderTemplate(orderTemplate.id, orderTemplate)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                setError(processError(err));
                // if (err.response && err.response.status === 401) {
                //     btnClose.current.click();
                // }
            })
    }

    function calcTrailingStopOrderTemplate(orderTemplate) {
        if (!orderTemplate.stopPriceMultiplier || !orderTemplate.limitPrice) return;

        return calcTrailingStop(orderTemplate.side, orderTemplate.limitPrice, orderTemplate.stopPriceMultiplier);
    }

    function checkIsTrailingStopOrderTemplate() {
        if (orderTemplate.type !== orderTypes.TRAILING_STOP) return;

        let stopPrice = calcTrailingStopOrderTemplate(orderTemplate);
        if (!stopPrice) return;

        stopPrice = stopPrice.toString();
        console.log(stopPrice);
        setOrderTemplate(prevState => ({ ...prevState, stopPrice }));
    }

    function onInputChange(event) {
        setOrderTemplate(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
        checkIsTrailingStopOrderTemplate();
    }

    useEffect(() => {
        if (!orderTemplate || !orderTemplate.symbol) return;

        getIndexes()
            .then(indexes => {
                // indexKeys.BOOK | indexKeys.LAST_CANDLE | indexKeys.LAST_ORDER
                const indexesRegex = /^(BOOK|LAST_CANDLE|LAST_ORDER.(avgPrice|stopPrice|limitPrice))/;
                const filteredIndexes = indexes.filter(k => k.symbol === orderTemplate.symbol && indexesRegex.test(k.variable));
                setIndexes(filteredIndexes);
            })
            .catch(err => setError(processError(err)))
    }, [orderTemplate.symbol]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setError('');

        if (!props.data || !props.data.id)
            setOrderTemplate(DEFAULT_ORDER_TEMPLATE);
        else
            setOrderTemplate(props.data);

    }, [props.data]);

    function getPriceClasses(orderType) {
        if (!orderType) return "col-md-6 mb-3 d-none";

        return [orderTypes.MARKET, orderTypes.STOP_LOSS, orderTypes.TAKE_PROFIT, orderTypes.TRAILING_STOP].includes(orderType) ? "col-md-6 mb-3 d-none" : "col-md-6 mb-3";
    }

    function getIcebergClasses(orderType) {
        return orderType === orderTypes.ICEBERG ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    }

    function getStopPriceClasses(orderType) {
        return STOP_TYPES.indexOf(orderType) !== -1 ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    }

    useEffect(() => {
        initializeModal('modalOrderTemplate', () => setOrderTemplate({ ...DEFAULT_ORDER_TEMPLATE }));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <div className="modal fade" id="modalOrderTemplate" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{orderTemplate.id ? 'Edit' : 'New'} Order Template</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className='form-group'>

                            <div className='row'>
                                <div className='col-md-4 mb-3'>
                                    <div className="form-group">
                                        <label htmlFor="symbol">Symbol</label>
                                        <SelectSymbol symbol={orderTemplate.symbol} onChange={onInputChange} disabled={orderTemplate.id > 0} />
                                    </div>
                                </div>
                                <div className='col-md-4 mb-3'>
                                    <SelectSide side={orderTemplate.side} onChange={onInputChange} />
                                </div>
                                <div className='col-md-4 mb-3'>
                                    <OrderType type={orderTemplate.type} onChange={onInputChange} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Name:</label>
                                        <input type='text' className='form-control' id='name' placeholder="My Template name" value={getValueOrDefault(orderTemplate.name)} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            {
                                orderTemplate.type === orderTypes.TRAILING_STOP
                                    ? <TrailingTemplate data={orderTemplate} onChange={onInputChange} />
                                    : <React.Fragment></React.Fragment>
                            }
                            <div className='row'>
                                <div className={getPriceClasses(orderTemplate.type)}>
                                    <PriceTemplate id='limitPrice' text='Unit Price:' indexes={indexes} onChange={onInputChange} price={orderTemplate.limitPrice} multiplier={orderTemplate.limitPriceMultiplier} />
                                </div>
                                <div className={getStopPriceClasses(orderTemplate.type)}>
                                    <PriceTemplate id='stopPrice' text='Stop Price:' indexes={indexes} onChange={onInputChange} price={orderTemplate.stopPrice} multiplier={orderTemplate.stopPriceMultiplier} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <QuantityTemplate id='quantity' text='Quantity:' quantity={orderTemplate.quantity} multiplier={orderTemplate.quantityMultiplier} onChange={onInputChange} />
                                </div>
                                <div className={getIcebergClasses(orderTemplate.type)}>
                                    <QuantityTemplate id='icebergQty' text='Iceberg Qty:' quantity={orderTemplate.icebergQty} multiplier={orderTemplate.icebergQtyMultiplier} onChange={onInputChange} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderTemplateModal;