import React, { useEffect, useState } from 'react';
import { getEventObject } from '../../../utils/EventUtils';
import { shareTypes } from '../../../utils/StrategiesUtils';

/**
 * props:
 * - sharedWith
 * - onChange
 */
function ShareInput(props) {

    const [sharedWith, setSharedWith] = useState(shareTypes.NONE);
    const [showSharedWith, setShowSharedWith] = useState(false);
    const [index, setIndex] = useState(0);

    function updateButton(sharedWith) {
        if (sharedWith === shareTypes.NONE)
            setIndex(0);
        else if (sharedWith === shareTypes.EVERYONE)
            setIndex(1);
        else
            setIndex(2);
    }

    useEffect(() => {
        updateButton(props.sharedWith);
        setSharedWith(props.sharedWith);
        setShowSharedWith(props.sharedWith !== shareTypes.EVERYONE && props.sharedWith !== shareTypes.NONE);
    }, [props.sharedWith]);

    function getActiveClass(isActive) {
        return isActive ? 'btn btn-primary' : 'btn btn-gray-100';
    }

    function getVisibleClass(show) {
        return show ? 'col-12 mb-3' : 'd-none';
    }

    function onShareClick(event) {
        const id = event.target.id;
        let sharedWith;
        switch(id) {
            case 'btnDontShare'    : sharedWith = shareTypes.NONE; break;
            case 'btnSharePublicly': sharedWith = shareTypes.EVERYONE; break;
            case 'btnShareWith'    : sharedWith = ''; break;
            default: sharedWith = ''; break;
        }
        props.onChange(getEventObject('sharedWith', sharedWith));
    }

    return (
        <div className='row'>
            <div className='col-md-12 mb-3'>
                <div className='form-group'>
                    <label htmlFor='shareWith'>Share Strategy? </label>
                    <div className='btn-group' role='group'>
                        <button id='btnDontShare' className={getActiveClass(index === 0)} onClick={onShareClick}>Don't Share</button>
                        <button id='btnSharePublicly' className={getActiveClass(index === 1)} onClick={onShareClick}>Share Publicly</button>
                        <button id='btnShareWith' className={getActiveClass(index === 2)} onClick={onShareClick}>Share With...</button>
                    </div>
                </div>
            </div>
            <div className={getVisibleClass(showSharedWith)}>
                <input type='text' id='sharedWith' className='form-control' onChange={props.onChange} value={sharedWith ? sharedWith : ''} placeholder="Comma separated emails" />
            </div>
        </div>
    )
}

export default ShareInput;