import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import useWebSocket from 'react-use-websocket';
import Menu from '../../components/Menu/Menu';
import Ticker from './Ticker/Ticker';
import Book from './Book/Book';
import Wallet from './Wallet/Wallet';
import CandleChart from './CandleChart';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import SelectSymbol from '../../components/SelectSymbol/SelectSymbol';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { getEmptyMessage } from '../../utils/MessageUtils';
import { DEFAULT_SYMBOL } from '../../utils/SymbolUtils';
import { getStoredValue, setStoredValue } from '../../utils/StorageUtils';
import SetHomePageButton from '../../components/SetHomePageButton/SetHomePageButton';
import { HOME_DASHBOARD } from '../../utils/HomePageUtils';
import AppWebSocket from '../../components/AppWebSocket/AppWebSocket';

function Dashboard() {
    const history = useHistory();

    const [tickerState, setTickerState] = useState({});

    const [bookState, setBookState] = useState({});
    const [highestGap, setHighestGap] = useState({});

    const [balanceState, setBalanceState] = useState({});

    // eslint-disable-next-line no-unused-vars
    const [wallet, setWallet] = useState({});

    const [chartSymbol, setChartSymbol] = useState(loadChartSymbol());

    const [notification, setNotification] = useState(getEmptyMessage());

    function loadChartSymbol() {
        return getStoredValue('dashboardChartSymbol', DEFAULT_SYMBOL);
    }

    function saveChartSymbol(value) {
        setStoredValue('dashboardChartSymbol', value);
    }

    function onWalletUpdate(walletObj) {
        setWallet(walletObj);
    }

    function onOrderSubmit(order) {
        history.push('/orders/' + order.symbol);
    }

    /*
    const { lastJsonMessage: message } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => console.log(`Connected to App WS Server`),
        onMessage: () => {
            if (message) {
                if (message.ticker) setTickerState(message.ticker);
                if (message.book) {
                    message.book.forEach(b => {
                        if (b.highestGap)
                            setHighestGap(b.highestGap);
                        else
                            bookState[b.symbol] = b;
                    });
                    setBookState(bookState);
                }
                if (message.balance) setBalanceState(message.balance);
            }
        },
        queryParams: { "token": getToken() }, // parametros no get
        onError: (event) => setNotification(processWebSocketError(event)),
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
        // reconnectAttempts: 20 (default)
    })
    */

    function onAppMessage(message) {
        if (message.ticker) setTickerState(message.ticker);
        if (message.book) {
            message.book.forEach(b => {
                if (b.highestGap)
                    setHighestGap(b.highestGap);
                else
                    bookState[b.symbol] = b;
            });
            setBookState(bookState);
        }
        if (message.balance) setBalanceState(message.balance);
    } 
    
    function onAppNotification(notification) {
        setNotification(notification);
    }

    function onChangeSymbol(event) {
        saveChartSymbol(event.target.value);
        setChartSymbol(event.target.value);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <div className='btn-toolbar mb-md-0'>
                            <SetHomePageButton value={HOME_DASHBOARD} />
                            <h1 className="h4">Dashboard</h1>
                        </div>
                    </div>
                    <div className='btn-toolbar mb-md-0'>
                        <div className="d-inline-flex align-items-center">
                            <SelectSymbol symbol={chartSymbol} hideSelectOption={true} onChange={onChangeSymbol} />
                        </div>
                        <div className="ms-2 ms-lg-3">
                            <NewOrderButton />
                        </div>
                    </div>
                </div>
                <CandleChart symbol={chartSymbol} />
                <Ticker data={tickerState} />
                <div className="row">
                    <Book data={bookState} highestGap={highestGap} />
                    <Wallet data={balanceState} onUpdate={onWalletUpdate} />
                </div>
                <Footer />
            </main>
            <NewOrderModal onSubmit={onOrderSubmit} />
            <AppWebSocket onMessage={onAppMessage} onNotification={onAppNotification} />
            <Toast type={notification.type} text={notification.text} listenMessages={false} />
        </React.Fragment>
    );
}

export default Dashboard;