import React from 'react';
import RowDeleteButton from '../../components/ActionButtons/RowDeleteButton';
import RowEditButton from '../../components/ActionButtons/RowEditButton';

/**
 * props:
 * - data
 * - onEditClick
 * - onDeleteClick
 */
function OrderTemplateRow(props) {

    return (
        <tr>
            <td>
                {props.data.symbol}
            </td>
            <td>
                {props.data.name}
            </td>
            <td>
                <span className='fw-normal'>{props.data.side}</span>
            </td>
            <td>
                <span className='fw-normal'>{props.data.type}</span>
            </td>
            <td>
                <RowEditButton id={props.data.id} title='Edit this Order Template' modalId='modalOrderTemplate' onClick={props.onEditClick} />
                <RowDeleteButton id={props.data.id} title='Delete this Order Template' onClick={props.onDeleteClick} />
            </td>
        </tr>
    );
}

export default OrderTemplateRow;