import React, { useRef, useState, useEffect } from 'react';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import SelectSymbol from '../../../components/SelectSymbol/SelectSymbol';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import { processError } from '../../../services/ServiceUtils';
import { saveStrategy } from '../../../services/StrategiesService';
import { initializeModal } from '../../../utils/ModalUtils';
import { DEFAULT_STRATEGY } from '../../../utils/StrategiesUtils';
import SelectAutomation from './SelectAutomation';
import SelectMonitor from './SelectMonitor';
import ShareInput from './ShareInput';

/**
 * props:
 * - data
 * - onSubmit
 */
function StrategyModal(props) {
    const [error, setError] = useState('');
    const [strategy, setStrategy] = useState(DEFAULT_STRATEGY);

    const btnClose = useRef('');
    const btnSave = useRef('');

    useEffect(() => {
        initializeModal('modalStrategy', () => setStrategy({ ...DEFAULT_STRATEGY }));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    useEffect(() => {
        if (!props.data || !props.data.id)
            setStrategy({ ...DEFAULT_STRATEGY });
        else
            setStrategy(props.data);
    }, [props.data]);

    function onSubmit(event) {
        saveStrategy(strategy.id, strategy)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)))
        props.onSubmit(event);
    }

    function onInputChange(event) {
        setStrategy(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    return (
        <div className="modal fade" id="modalStrategy" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Strategy</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label htmlFor='symbol'>Symbol: </label>
                                        <SelectSymbol onChange={onInputChange} symbol={strategy.symbol} disabled={strategy.id > 0} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Name:</label>
                                        <input type='text' id='name' className='form-control' placeholder='Strategy name' onChange={onInputChange} value={strategy.name ? strategy.name : ''} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='monitorId'>Monitor: </label>
                                        <SelectMonitor symbol={strategy.symbol} monitorId={strategy.monitorId} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='buyAutomationId'>Buy Automation: </label>
                                        <SelectAutomation symbol={strategy.symbol} id='buyAutomationId' automationId={strategy.buyAutomationId} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='sellAutomationId'>Sell Automation: </label>
                                        <SelectAutomation symbol={strategy.symbol} id='sellAutomationId' automationId={strategy.sellAutomationId} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <ShareInput sharedWith={strategy.sharedWith} onChange={onInputChange} />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <SwitchInput id='isActive' text='Is Active?' onChange={onInputChange} isChecked={strategy.isActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                            Save
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default StrategyModal;