import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../components/Pagination/Pagination';
import Footer from '../../components/Footer/Footer';
import { processError } from '../../services/ServiceUtils';
import OrderTemplateRow from './OrderTemplateRow';
import OrderTemplateModal from './OrderTemplateModal/OrderTemplateModal';
import Toast from '../../components/Toast/Toast';
import { getOrderTemplates, deleteOrderTemplate } from '../../services/OrderTemplatesService';
import SearchSymbol from '../../components/SearchSymbol/SearchSymbol';
import NewOrderTemplateButton from './NewOrderTemplateButton';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import { findRowFromActionButton, getIdFromActionButton } from '../../utils/DataRowButtonUtils';

function OrderTemplates() {

    const defaultLocation = useLocation();

    const history = useHistory();

    const [orderTemplates, setOrderTemplates] = useState([]);
    const [page, setPage] = useState(getPage());
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [notification, setNotification] = useState(getEmptyMessage());

    const [editOrderTemplate, setEditOrderTemplate] = useState({});

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => setPage(getPage(location)));
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getOrderTemplates(search, page || 1)
            .then(result => {
                setCount(result.count);
                setOrderTemplates(result.rows ? result.rows : []);
                // if (result.rows.length > 0)
                //     setEditOrderTemplate(result.rows[0]);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))

    }, [page, search]);

    function onEditOrderTemplateClick(event) {
        setEditOrderTemplate(findRowFromActionButton(event, orderTemplates));
    }

    function onDeleteOrderTemplateClick(event) {
        deleteOrderTemplate(getIdFromActionButton(event))
            .then(orderTemplate => history.go(0))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onOrderTemplateSubmit(event) {
        history.go(0);
    }

    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Order Templates</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <NewOrderTemplateButton />
                        </div>
                        <div className='btn-group ms-2 ms-lg-3'>
                            <SearchSymbol onChange={onSearchChange} placeholder={search} />
                        </div>

                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Symbol</th>
                                <th className='border-gray-200'>Name</th>
                                <th className='border-gray-200'>Side</th>
                                <th className='border-gray-200'>Type</th>
                                <th className='border-gray-200'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderTemplates && orderTemplates.length
                                    ? orderTemplates.map(ot => (<OrderTemplateRow key={ot.id} data={ot} onEditClick={onEditOrderTemplateClick} onDeleteClick={onDeleteOrderTemplateClick} />))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <OrderTemplateModal data={editOrderTemplate} onSubmit={onOrderTemplateSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default OrderTemplates;