import React, { useRef, useState, useEffect } from 'react';
import { processError } from '../../../services/ServiceUtils';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import SelectCoin from '../../../components/SelectCoin/SelectCoin';
import SelectNetwork from './SelectNetwork';
import AmountTemplate from './AmountTemplate';
import { saveWithdrawTemplate } from '../../../services/WithdrawTemplatesService';
import { getValueOrDefault, initializeModal } from '../../../utils/ModalUtils';

/**
 * props:
 * - data
 * - onSubmit
 */
function WithdrawTemplateModal(props) {

    const DEFAULT_WITHDRAW_TEMPLATE = {
        name: '',
        coin: '',
        network: '',
        address: '',
        addressTag: '',
        amount: '',
        amountMultiplier: 1
    };

    const [error, setError] = useState('');

    const [withdrawTemplate, setWithdrawTemplate] = useState(DEFAULT_WITHDRAW_TEMPLATE);

    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        saveWithdrawTemplate(withdrawTemplate.id, withdrawTemplate)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)));
    }

    function onInputChange(event) {
        setWithdrawTemplate(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        setError('');
        setWithdrawTemplate(props.data);
    }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const COINS_WITH_TAG = ['XRP'];

    useEffect(() => {
        initializeModal('modalWithdrawTemplate', () => setWithdrawTemplate({ ...DEFAULT_WITHDRAW_TEMPLATE }));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);


    return (
        <div className="modal fade" id="modalWithdrawTemplate" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{withdrawTemplate.id ? 'Edit' : 'New'} Withdraw Template</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='coin'>Coin:</label>
                                        <SelectCoin coin={withdrawTemplate.coin} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='network'>Network:</label>
                                        <SelectNetwork coin={withdrawTemplate.coin} network={withdrawTemplate.network} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Name:</label>
                                        <input type='text' id='name' value={getValueOrDefault(withdrawTemplate.name)} className='form-control' placeholder='My template name' onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='address'>Wallet Address:</label>
                                        <input type='text' id='address' value={getValueOrDefault(withdrawTemplate.address)} className='form-control' placeholder='...' onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            {
                                COINS_WITH_TAG.includes(withdrawTemplate.coin)
                                    ? (
                                        <div className='row'>
                                            <div className='col-md-12 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='addressTag'>Address Tag:</label>
                                                    <input type='text' id='addressTag' value={getValueOrDefault(withdrawTemplate.addressTag)} className='form-control' placeholder='...' onChange={onInputChange} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : <React.Fragment></React.Fragment>
                            }
                            <div className='row'>
                                <div className='col-md-7 mb-3'>
                                    <AmountTemplate amount={withdrawTemplate.amount} multiplier={withdrawTemplate.amountMultiplier} onChange={onInputChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithdrawTemplateModal;