import React, {useState} from "react";
import { isCurrentHomePage, setHomePage } from "../../utils/HomePageUtils";

/**
 * props:
 * - value
 */
function SetHomePageButton(props) {

    const SELECTED_COLOR = 'yellow';
    const NOT_SELECTED_COLOR = 'currentColor';

    const [fillColor, setFillColor] = useState(getFillColor());

    function getFillColor() {
        return isCurrentHomePage(props.value) ? SELECTED_COLOR : NOT_SELECTED_COLOR;
    }

    function setDefaultHomePage(event) {
        if (props.value) {
            setHomePage(props.value);
            setFillColor(SELECTED_COLOR);
        }
    }

    return (
        <span className='btn btn-primary btn-sm ms-2 me-2 h-100' title='Set this as default home page' onClick={setDefaultHomePage}>
            <svg className="icon icon-xs" fill={fillColor} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" /></svg>
        </span>
    )
}

export default SetHomePageButton;