import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const MONITORS_URL = getBaseUrl('/monitors/');

export async function getMonitors(page) {
    return await appRequest.doGetRequest(`${MONITORS_URL}?page=${page}`);
}

export async function getMonitorsBySymbol(symbol) {
    return await appRequest.doGetRequest(`${MONITORS_URL}?symbol=${symbol}`);
}

export async function saveMonitor(id, newMonitor) {
    if (id)
        return await appRequest.doPatchRequest(`${MONITORS_URL}${id}`, newMonitor);
    else
        return await appRequest.doPostRequest(MONITORS_URL, newMonitor);
}

export async function startMonitor(id) {
    return await appRequest.doPostRequest(`${MONITORS_URL}${id}/start`, {});
}

export async function stopMonitor(id) {
    return await appRequest.doPostRequest(`${MONITORS_URL}${id}/stop`, {});
}

export async function deleteMonitor(id) {
    return await appRequest.doDeleteRequest(`${MONITORS_URL}${id}`);
}