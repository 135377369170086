import React, { useMemo, useState, useEffect } from 'react';
import { getEventObject } from '../../utils/EventUtils';

/**
 * props:
 * - id
 * - quantity
 * - isQuote
 * - text
 * - symbol
 * - allowQuote
 * - onChange
 */
function QuantityInput(props) {

    const [isQuote, setIsQuote] = useState(false);

    useEffect(() => {
        setIsQuote(props.isQuote === 'true' || props.isQuote === true);
    }, [props.isQuote]);

    function onCalcClick(event) {
        props.onChange(getEventObject('isQuote', !isQuote));
    }

    function getPlaceHolder() {
        return isQuote ? props.symbol.minNotional : props.symbol.minLotSize;
    }

    const quantityInput = useMemo(() => (
        <div className='form-group'>
            <label htmlFor={props.id}>{props.text}</label>
            <div className='input-group'>
                {
                    props.allowQuote
                        ? <button type='button' className='btn btn-secondary d-inline-flux align-items-center' onClick={onCalcClick}>
                            {isQuote ? props.symbol.quote : props.symbol.base}
                        </button>
                        : <React.Fragment></React.Fragment>
                }
                <input type='number' id={props.id} value={props.quantity} className='form-control' placeholder={getPlaceHolder()} onChange={props.onChange} />
            </div>
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [props.quantity, props.isQuote, props.allowQuote, props.symbol, isQuote]);

    return quantityInput;
}

export default QuantityInput;