import React, { useRef, useState, useEffect } from 'react';
import { getSymbol } from '../../services/SymbolsService';
import { processError } from '../../services/ServiceUtils';
import SelectSymbol from '../SelectSymbol/SelectSymbol';
import SymbolPrice from '../SymbolPrice/SymbolPrice';
import WalletSummary from '../WalletSummary/WalletSummary';
import SelectSide from './SelectSide';
import OrderType from './OrderType';
import QuantityInput from './QuantityInput';
import { isBuyOrder, orderSides, orderTypes, STOP_TYPES } from '../../utils/OrderUtils';
import { placeOrder } from '../../services/OrdersService';
import ActionMessage from '../ActionMessage/ActionMessage';
import { getMemoryIndex } from '../../services/BeholderService';
import { indexKeys } from '../../utils/IndexUtils';
import { DEFAULT_SYMBOL } from '../../utils/SymbolUtils';
import { initializeModal, getValueOrDefault } from '../../utils/ModalUtils';
import { getFloatValue } from '../../utils/NumberUtils';

/**
 * props:
 * - onSubmit
 */
function NewOrderModal(props) {

    const [error, setError] = useState('');

    const DEFAULT_ORDER = {
        symbol: DEFAULT_SYMBOL,
        limitPrice: "0",
        stopPrice: "0",
        stopPriceMultiplier: "0",
        quantity: "0",
        side: orderSides.BUY,
        type: orderTypes.MARKET, // ou orderTypes.LIMIT
        isQuote: false
    }

    // isVisible: usado pra renderizar apenas quando a modal estiver sendo exibida, para evitar processamento de stream de um dos subcomponentes
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        initializeModal('modalOrder',
            () => {
                setIsVisible(false);
                setOrder({ ...DEFAULT_ORDER });
            },
            () => {
                setIsVisible(true);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [order, setOrder] = useState(DEFAULT_ORDER);

    const btnClose = useRef('');
    const btnSend = useRef('');
    const inputTotal = useRef('');

    const [symbol, setSymbol] = useState({});

    function errorHandling(err) {
        setError(processError(err));
        // if (err.response && err.response.status === 401) {
        //     btnClose.current.click();
        // }
    }

    function onSubmit(event) {
        const reloadPage = (order.type !== orderTypes.TRAILING_STOP);
        placeOrder(order)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result, reloadPage);
            })
            .catch(err => errorHandling(err))
    }

    function onInputChange(event) {
        setOrder(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
        // console.log(event);
    }

    useEffect(() => {
        if (!order.symbol) return;

        getSymbol(order.symbol)
            .then(symbolObject => {
                if (symbolObject) return setSymbol(symbolObject);
                throw new Error(`Symbol not found`);
            })
            .catch(err => errorHandling(err))
    }, [order.symbol]);

    function setFormError(errorMessage) {
        btnSend.current.disabled = true;
        return setError(errorMessage);
    }

    function validateOrderFields() {
        setError('');
        btnSend.current.disabled = false;

        const quantity = getFloatValue(order.quantity);
        if (!quantity) return;

        if (!order.isQuote) {
            if (quantity < parseFloat(symbol.minLoteSize)) {
                return setFormError(`Min Lote Size: ${symbol.minLoteSize}`);
            }
        }

        const price = parseFloat(order.limitPrice);
        if (!price) return;

        const total = order.isQuote ? quantity : quantity * price;
        if (inputTotal.current)
            inputTotal.current.value = `${total}`.substring(0, 8);

        const minNotional = parseFloat(symbol.minNotional);
        if (total < minNotional) {
            return setFormError(`Min Notional: ${symbol.minNotional}`);
        }
    }

    useEffect(() => {
        return validateOrderFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order.quantity, order.limitPrice]);

    function isOrderTypeForPrice(orderType) {
        return [orderTypes.MARKET, orderTypes.STOP_LOSS, orderTypes.TAKE_PROFIT, orderTypes.TRAILING_STOP].includes(orderType);
    }

    function getPriceClasses(orderType) {
        return isOrderTypeForPrice(orderType) ? "col-md-6 mb-3 d-none" : "col-md-6 mb-3";
    }

    function getStopPriceClasses(orderType) {
        return STOP_TYPES.indexOf(orderType) !== -1 ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    }

    function getTrailingStopClasses(orderType) {
        return orderType === orderTypes.TRAILING_STOP ? 'row' : 'd-none';
    }

    function updateTotal(book) {
        const savedTotalValue = inputTotal.current.value;
        const quantity = parseFloat(order.quantity);
        if (!quantity) return;

        btnSend.current.disabled = false;
        if (isBuyOrder(order))
            inputTotal.current.value = `${quantity * parseFloat(book.ask)}`.substring(0, 8);
        else
            inputTotal.current.value = `${quantity * parseFloat(book.bid)}`.substring(0, 8);

        if (parseFloat(inputTotal.current.value) < order.minNotional) {
            return setFormError(`Min Notional: ${order.minNotional}`);
        }

        const changedValue = (inputTotal.current.value !== savedTotalValue);
        if (changedValue) {
            validateOrderFields();
        }
    }

    function updateLimitPrice(book) {
        if (!parseFloat(order.limitPrice))
            setOrder(prevState => ({ ...prevState, limitPrice: parseFloat(book.bid) }));
    }

    function onPriceChange(book) {
        if (!btnSend || !btnSend.current) return;
        if (!inputTotal || !inputTotal.current) return;
        if (!isOrderTypeForPrice(order.type)) return;

        updateTotal(book);
        updateLimitPrice(book);
    }

    const [wallet, setWallet] = useState({
        base: {
            symbol: '',
            qty: 0
        },
        quote: {
            symbol: '',
            qty: 0
        }
    });

    async function loadWallet(symbol) {
        try {
            const baseQty = await getMemoryIndex(symbol.base, indexKeys.WALLET, '');
            const quoteQty = await getMemoryIndex(symbol.quote, indexKeys.WALLET, '');
            setWallet({
                base: { symbol: symbol.base, qty: baseQty },
                quote: { symbol: symbol.quote, qty: quoteQty }
            });
        }
        catch (err) {
            setError(processError(err));
        }
    }

    useEffect(() => {
        if (!symbol || !symbol.base) return;

        loadWallet(symbol);
    }, [symbol]);

    function onSymbolChange(event) {
        setError('');
        setOrder({ ...DEFAULT_ORDER, symbol: event.target.value });
    }

    return (
        <div className="modal fade" id="modalOrder" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">New Order</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className='form-group'>

                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label htmlFor="symbol">Symbol</label>
                                        <SelectSymbol symbol={order.symbol} disabled={false} onChange={onSymbolChange} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    {
                                        isVisible && order.symbol
                                            ? <SymbolPrice symbol={order.symbol} onChange={onPriceChange} />
                                            : <React.Fragment></React.Fragment>
                                    }
                                </div>
                            </div>

                            <div className='row'>
                                <label>You have:</label>
                            </div>
                            <WalletSummary wallet={wallet} />

                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <SelectSide side={order.side} onChange={onInputChange} />
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <OrderType type={order.type} onChange={onInputChange} />
                                </div>
                            </div>

                            <div className={getTrailingStopClasses(order.type)}>
                                <div className='col-md-6 mb-3'>
                                    <label htmlFor='limitPrice'>Activation Price:</label>
                                    <input id='limitPrice' type='number' className='form-control' placeholder='0' value={getValueOrDefault(order.limitPrice)} onChange={onInputChange} />
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label htmlFor='stopPriceMultiplier'>Callback Rate:</label>
                                    <div className='input-group'>
                                        <input id='stopPriceMultiplier' type='number' className='form-control' placeholder='1' value={getValueOrDefault(order.stopPriceMultiplier)} onChange={onInputChange} />
                                        <span className='input-group-text bg-secondary'>%</span>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className={getPriceClasses(order.type)}>
                                    <div className='form-group'>
                                        <label htmlFor='limitPrice'>Unit Price:</label>
                                        <input type='number' className='form-control' id='limitPrice' placeholder='0' value={order.limitPrice} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <QuantityInput id="quantity" text="Quantity:" quantity={order.quantity} symbol={symbol} isQuote={order.isQuote} allowQuote={order.type === orderTypes.MARKET} onChange={onInputChange} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className={getStopPriceClasses(order.type)}>
                                    <div className='form-group'>
                                        <label htmlFor='stopPrice'>Stop Price:</label>
                                        <input className='form-control' id='stopPrice' type='number' value={getValueOrDefault(order.stopPrice)} onChange={onInputChange} placeholder={order.stopPrice} />
                                    </div>
                                </div>
                                {
                                    order.isQuote
                                        ? <React.Fragment></React.Fragment>
                                        : (
                                            <div className='col-md-6 mb-3'>
                                                <div className='form-group'>
                                                    <label htmlFor='total'>Total Price:</label>
                                                    <input ref={inputTotal} className='form-control' id='total' type='number' placeholder='0' disabled />

                                                </div>
                                            </div>
                                        )
                                }
                            </div>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <button ref={btnSend} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                                <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd"></path>
                            </svg>
                            Execute Order
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewOrderModal;