import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { processWebSocketError } from '../../utils/MessageUtils';

/**
 * props:
 * - symbol
 * - serviceName
 * - onMessage 
 */
function BinanceWebSocket(props) {

    const SERVICE_BOOK_TICKER = 'bookTicker';

    const [symbol, setSymbol] = useState('');
    const [serviceName, setServiceName] = useState(SERVICE_BOOK_TICKER);

    useEffect(() => {
        if (!props.symbol) return;

        setSymbol(props.symbol.toLowerCase());
        if (props.serviceName)
            setServiceName(props.serviceName);
    }, [props.symbol, props.serviceName]);

    // useEffect(() => {
    //     if (!symbol) return;
    //     if (!serviceName) return;


    // }, [symbol, serviceName]);

    function getBinanceService() {
        return `${symbol}@${serviceName}`;
    }

    function getBinanceWsUrl() {
        return `${process.env.REACT_APP_BWS_URL}/${getBinanceService()}`;
    }

    const { lastJsonMessage, sendJsonMessage } = useWebSocket(getBinanceWsUrl(), {
        onOpen: () => {
            console.log(`Connected to Binance Stream ${symbol}`);
            sendJsonMessage({
                method: "SUBSCRIBE",
                params: [getBinanceService()],
                id: 1
            })
        },
        onMessage: () => {
            if (lastJsonMessage) {
                if (serviceName === SERVICE_BOOK_TICKER) {
                    const b = { bid: lastJsonMessage.b, ask: lastJsonMessage.a };
                    props.onMessage(b);
                }
                else 
                    props.onMessage(lastJsonMessage);
            }
        },
        onError: (event) => processWebSocketError(event),
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
    });

    return (
        <React.Fragment></React.Fragment>
    )
}

export default BinanceWebSocket;