import React, { useState, useEffect } from 'react';
import { formatNumber } from '../../../utils/NumberUtils';
import { orderSides } from '../../../utils/OrderUtils';

/**
 * props:
 * - data
 */
function GridTable(props) {

    const [grids, setGrids] = useState([]);

    useEffect(() => {
        if (!props.data || !props.data.length) return;
        setGrids(props.data);
    }, [props.data]);

    function getItem(conditions) {
        const value = conditions.split(' && ')[0].split(/[><]/)[1];
        return formatNumber(value);
    }

    function getGridItems(side) {
        if (!grids || !grids.length) return [];
        
        return grids
            .filter(g => g.orderTemplate.side === side)
            .sort((a,b) => a.id - b.id);
    }

    return (
        <div className='row'>
            <div className='col-md-6'>
                <ul className='list-group'>
                    <li className='list-group-item list-group-item-warning text-end'>BUY Levels</li>
                    { 
                        getGridItems(orderSides.BUY).map(g => (
                            <li key={g.id} className='list-group-item text-end'>{getItem(g.conditions)}</li>
                        ))
                    }
                </ul>
            </div>
            <div className='col-md-6'>
                <ul className='list-group'>
                    <li className='list-group-item list-group-item-success text-end'>SELL Levels</li>
                    {
                        getGridItems(orderSides.SELL).map(g => (
                            <li key={g.id} className='list-group-item text-end'>{getItem(g.conditions)}</li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default GridTable;