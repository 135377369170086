import React from "react";

function NewMonitorButton() {
    return (
        <button id='btnNewMonitor' className='btn btn-primary animate-up-2' data-bs-toggle='modal' data-bs-target='#modalMonitor'>
            <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
            New Monitor
        </button>
    )
}

export default NewMonitorButton;