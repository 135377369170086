import React from 'react';
import { fiatList, getDefaultFiat } from '../../utils/FiatUtils';

/**
 * props:
 * - onChange
 */
function SelectFiat(props) {

    // {... props.forSelectSymbol ? {value: quote} : {defaultValue: quote}}
    return (
        <select id="selectFiat" className="form-select pe-6" defaultValue={getDefaultFiat()} onChange={props.onChange}>
            {
                Object.entries(fiatList).map(prop => (
                    <option key={prop[0]} value={prop[0]}>{prop[1]}</option>
                ))
            }
        </select>
    )
}

export default SelectFiat;