import React, { useState, useEffect } from 'react';
import { processError } from '../../services/ServiceUtils';
import { getAllStrategies } from '../../services/StrategiesService';
import { getEventObject } from '../../utils/EventUtils';

/**
 * props:
 * - symbol
 * - strategyId
 * - onlyActives
 * - showLabel
 * - onChange
 */
function SelectStrategy(props) {

    const [strategies, setStrategies] = useState([]);
    const [strategyId, setStrategyId] = useState('');

    useEffect(() => {
        if (!props.strategyId) return setStrategyId('');

        setStrategyId(props.strategyId);
    }, [props.strategyId]);

    useEffect(() => {
        getAllStrategies(props.symbol, props.onlyActives)
            .then(result => setStrategies(result))
            .catch(err => setStrategies([processError(err)]));
    }, [props.symbol, props.onlyActives]);

    function onStrategyChange(event) {
        if (!event.target.value) {
            // selecionou opcao nenhum
            props.onChange(getEventObject('strategyId', null));
            return;
        }

        const id = parseInt(event.target.value);
        const strategy = strategies.find(s => s.id === id);
        const automationIds = strategy.sellAutomationId !== strategy.buyAutomationId ? [strategy.sellAutomationId, strategy.buyAutomationId] : [strategy.sellAutomationId];
        if (props.onChange) {
            // props.onChange(strategy);
            props.onChange(getEventObject('strategyId', strategy.id));
            props.onChange(getEventObject('automationIds', automationIds));
        }
    }

    return (
        <select className='form-select' id='strategy' value={strategyId || ''} onChange={onStrategyChange}>
            <option value=''>Select strategy...</option>
            {
                strategies && strategies.length
                    ? strategies.map(s => (<option key={s.id} value={s.id}>{s.name}</option>))
                    : <React.Fragment></React.Fragment>
            }
        </select>
    )
}

export default SelectStrategy;