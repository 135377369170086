import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import SelectQuote from '../../components/SelectQuote/SelectQuote';
import { getDefaultQuote } from '../../utils/QuoteUtils';
import DateFilter from '../../components/DateFilter/DateFilter';
import LineChart from './LineChart';
import { getOrdersReport, getDayTradeReport } from '../../services/OrdersService';
import { processError } from '../../services/ServiceUtils';
import Toast from '../../components/Toast/Toast';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import Wallet from '../Dashboard/Wallet/Wallet';
import AutomationReport from './AutomationReport';
import Footer from '../../components/Footer/Footer';
import { getErrorMessage } from '../../utils/MessageUtils';
import dateUtils, { MONTH_MODE } from "../../utils/DateUtils";
import { getStoredValue, setStoredValue } from '../../utils/StorageUtils';
import SetHomePageButton from '../../components/SetHomePageButton/SetHomePageButton';
import { HOME_REPORTS } from '../../utils/HomePageUtils';
// import SelectAutomation from '../../components/SelectAutomation/SelectAutomation';
import SelectStrategy from '../../components/SelectStrategy/SelectStrategy';

function Reports() {

    const dateMode = loadDateMode();

    const DEFAULT_FILTER = {
        symbol: loadQuote(),
        automationId: loadAutomationId(),
        strategyId: loadStrategyId(),
        dateMode: dateMode,
        startDate: dateUtils.getInicialStartDate(dateMode),
        endDate: dateUtils.getInicialEndDate()
    };

    function loadQuote() {
        return getStoredValue('reportsQuote', getDefaultQuote());
    }

    function saveQuote(value) {
        setStoredValue('reportsQuote', value);
    }

    function loadDateMode() {
        return getStoredValue('reportsDateMode', MONTH_MODE);
    }

    function saveDateMode(value) {
        setStoredValue('reportsDateMode', value);
    }

    function loadAutomationId() {
        return getStoredValue('reportsAutomationId', '');
    }

    function saveAutomationId(value) {
        setStoredValue('reportsAutomationId', value);
    }

    function loadStrategyId() {
        return getStoredValue('reportsStrategyId', '');
    }

    function saveStrategyId(value) {
        setStoredValue('reportsStrategyId', value);
    }

    const [filter, setFilter] = useState(DEFAULT_FILTER);
    const [report, setReport] = useState({});
    const [notification, setNotification] = useState({});

    function onQuoteChange(event) {
        saveQuote(event.target.value);
        setFilter(prevState => ({
            ...prevState,
            symbol: event.target.value
        }));
    }

    function onDateChange(event) {
        const value = event.target.value;

        if (value.modeChanged === true) {
            value.startDate = dateUtils.getInicialStartDate(value.dateMode);
            value.endDate = dateUtils.getInicialEndDate();
        }

        setFilter(prevState => ({
            ...prevState,
            dateMode: value.dateMode,
            startDate: value.startDate,
            endDate: value.endDate
        }));

        saveDateMode(value.dateMode);
    }

    /*
    function onAutomationChange(event) {
        setFilter(prevState => ({
            ...prevState,
            automationId: event.target.value
        }));
        saveAutomationId(event.target.value);
    }
    */

    function saveSelectedStrategy(id, value) {
        if (id === 'strategyId')
            saveStrategyId(value);
        else
            saveAutomationId(value);
    }

    function onStrategyChange(event) {
        let id = event.target.id;
        if (id === 'automationIds') id = 'automationId';
        const value = event.target.value;

        if (!value) {
            const currentFilter = {...filter};
            currentFilter.automationId = '';
            currentFilter.strategyId = '';
            setFilter(currentFilter);

            saveSelectedStrategy('strategyId', currentFilter.strategyId);
            saveSelectedStrategy('automationId', currentFilter.automationId);
            return;
        }

        setFilter(prevState => ({
            ...prevState,
            [id]: value
        }));
        saveSelectedStrategy(id, value);
    }

    function loadReport() {
        let promise;
        const isDayTradeReport = filter.startDate && dateUtils.datesAreOnSameDay(filter.startDate, filter.endDate);

        if (isDayTradeReport)
            promise = getDayTradeReport(filter.symbol, filter.automationId, filter.startDate);
        else
            promise = getOrdersReport(filter.symbol, filter.automationId, filter.startDate, filter.endDate);

        promise
            .then(result => setReport(result))
            .catch(err => setNotification(getErrorMessage(processError(err))));
    }

    useEffect(() => {
        if (!filter || !filter.symbol) return setFilter({ symbol: getDefaultQuote() });

        loadReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.symbol, filter.automationId, filter.startDate, filter.endDate, filter.strategyId]);

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='row py-4'>
                    <div className='col-3'>
                        <div className='btn-toolbar mb-md-0'>
                            <SetHomePageButton value={HOME_REPORTS} />
                            <h2 className='h4'>Reports</h2>
                        </div>
                    </div>
                    <div className='col-md-4 mb-2'>
                        <div className='input-group input-group-merge'>
                            <SelectQuote value={filter.symbol} onChange={onQuoteChange} noFavorites={true} />
                            {/* <SelectAutomation quote={filter.symbol} automationId={filter.automationId} onlyActives={true} onChange={onAutomationChange} /> */}
                            <SelectStrategy symbol={filter.symbol} showLabel={false} strategyId={filter.strategyId} onlyActives={false} onChange={onStrategyChange} />
                        </div>
                    </div>
                    <div className='col-md-5 mb-2'>
                        <DateFilter dateMode={filter.dateMode} startDate={filter.startDate} endDate={filter.endDate} onClick={onDateChange} />
                    </div>
                </div>
                <LineChart data={report} />
                <div className='row'>
                    <InfoBlock title={`Buy Volume (${report.buyOrders} orders)`} prefix='$' value={report.buyVolume} background='secondary'>
                        <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                    </InfoBlock>
                    <InfoBlock title={`Sell Volume (${report.sellOrders} orders)`} prefix='$' value={report.sellVolume} background='success'>
                        <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
                        </svg>
                    </InfoBlock>
                    <InfoBlock title='Orders' value={report.orders} background='primary' precision={0}>
                        <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                            <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
                        </svg>
                    </InfoBlock>
                </div>
                <div className='row'>
                    <AutomationReport data={report.automations} />
                    <Wallet />
                </div>
                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default Reports;