import React, { useEffect, useState } from 'react';
import ActionType from './ActionType';
import ActionBadge from './ActionBadge';
import { processError } from '../../../../services/ServiceUtils';
import { getAllOrderTemplates } from '../../../../services/OrderTemplatesService';
import { getAllWithdrawTemplates } from '../../../../services/WithdrawTemplatesService';
import { getSymbol } from '../../../../services/SymbolsService';
import { orderTypes } from '../../../../utils/OrderUtils';
import { getEventObject } from '../../../../utils/EventUtils';
import { actionTypes } from '../../../../utils/ActionUtils';

/**
 * props:
 * - symbol
 * - actions
 * - onChange
 */
function ActionsArea(props) {

    const DEFAULT_ACTION = {
        type: actionTypes.ALERT_EMAIL,
        orderTemplateId: null,
        orderTemplateName: '',
        withdrawTemplateId: null,
        withdrawTemplateName: ''
    }

    const [newAction, setNewAction] = useState(DEFAULT_ACTION);
    const [actions, setActions] = useState([]);
    const [orderTemplates, setOrderTemplates] = useState([]);
    const [withdrawTemplates, setWithdrawTemplates] = useState([]);

    useEffect(() => {
        // orderna pelo id
        let auxActions = props.actions ? props.actions : [];
        auxActions = auxActions.sort((a, b) => {
            // a.id - b.id
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
        });
        setActions(auxActions);
        setNewAction(DEFAULT_ACTION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.actions]);

    useEffect(() => {
        if (!props.symbol) return;

        getAllOrderTemplates(props.symbol)
            .then(result => setOrderTemplates(result))
            .catch(err => processError(err));

        getSymbol(props.symbol)
            .then(symbol => {
                if (symbol) 
                    return getAllWithdrawTemplates(symbol.base);
                else
                    throw new Error(`No withdraw templates found.`);
            })
            .then(result => setWithdrawTemplates(result))
            .catch(err => processError(err))

    }, [props.symbol]);

    function onInputChange(event) {
        if (event.target.id === 'orderTemplateId') {
            const orderTemplateId = parseInt(event.target.value);
            const orderTemplate = orderTemplates.find(ot => ot.id === orderTemplateId);
            if (!orderTemplate) return;

            setNewAction(prevState => ({
                ...prevState,
                orderTemplateName: orderTemplate.name,
                orderTemplateId
            }));
        }
        else if (event.target.id === 'withdrawTemplateId') {
            const withdrawTemplateId = parseInt(event.target.value);
            const withdrawTemplate = withdrawTemplates.find(ot => ot.id === withdrawTemplateId);
            if (!withdrawTemplate) return;

            setNewAction(prevState => ({
                ...prevState,
                withdrawTemplateName: withdrawTemplate.name,
                withdrawTemplateId
            }));
        }
        else
            setNewAction(prevState => ({ ...prevState, [event.target.id]: event.target.value }));

        if (props.onChange) props.onChange(event);
    }
    
    function onAddClick(event) {
        if (newAction.type === actionTypes.ORDER) {
            if (!newAction.orderTemplateId) return;

            newAction.id = 'ot' + newAction.orderTemplateId; // id temporario

            const alreadyExists = actions.some(a => a.id === newAction.id);
            if (alreadyExists) return;
        }
        else if (newAction.type === actionTypes.WITHDRAW) {
            if (!newAction.withdrawTemplateId) return;

            newAction.id = 'wt' + newAction.withdrawTemplateId; // id temporario

            const alreadyExists = actions.some(a => a.id === newAction.id);
            if (alreadyExists) return;
        }
        else {
            newAction.id = newAction.type; // id temporario
            const alreadyExists = actions.some(a => a.type === newAction.type);
            if (alreadyExists) return;
        }

        // console.log(newAction);
        actions.push(newAction);
        // console.log(actions);
        setActions(actions);
        if (props.onChange) props.onChange(getEventObject('actions', actions));
        setNewAction(DEFAULT_ACTION);
    }

    function onRemoveActionClick(event) {
        const index = actions.find(a => event.target.id);
        actions.splice(index, 1);
        if (props.onChange) props.onChange(getEventObject('actions', actions));
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-12 my-3'>
                    <div className='input-group input-group-merge'>
                        <ActionType type={newAction.type} onChange={onInputChange} />
                        { 
                            newAction.type === actionTypes.ORDER && orderTemplates
                                ? <select id='orderTemplateId' className='form-select' onChange={onInputChange}>
                                    <option value='0'>Select one...</option>
                                    {
                                        orderTemplates.filter(ot => ot.type !== orderTypes.TRAILING_STOP).map(ot => (<option key={ot.id} value={ot.id}>{ot.name}</option>))
                                    }
                                </select>
                                : <React.Fragment></React.Fragment>
                        }
                        { 
                            newAction.type === actionTypes.TRAILING && orderTemplates
                                ? <select id='orderTemplateId' className='form-select' onChange={onInputChange}>
                                    <option value='0'>Select one...</option>
                                    {
                                        orderTemplates.filter(ot => ot.type === orderTypes.TRAILING_STOP).map(ot => (<option key={ot.id} value={ot.id}>{ot.name}</option>))
                                    }
                                </select>
                                : <React.Fragment></React.Fragment>
                        }
                        {
                            newAction.type === actionTypes.WITHDRAW && withdrawTemplates
                                ? <select id='withdrawTemplateId' className='form-select' onChange={onInputChange}>
                                    <option value='0'>Select one...</option>
                                    {
                                        withdrawTemplates.map(wt => (<option key={wt.id} value={wt.id}>{wt.name}</option>))
                                    }
                                </select>
                                : <React.Fragment></React.Fragment>
                        }
                        <button type='button' className='btn btn-secondary' onClick={onAddClick}>
                            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                </div>
            </div>
            {
                actions && actions.length > 0
                    ? (
                        <div className='divScrollBadges'>
                            <div className='d-inline-flex flex-row align-content-start'>
                                {
                                    actions.map(action => (<ActionBadge key={action.type + ':' + action.id} action={action} onClick={onRemoveActionClick} />))
                                }
                            </div>

                        </div>
                    )
                    : <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    )
}

export default ActionsArea;
