import React, { useEffect, useState, useMemo } from 'react';
import Symbol from '../../../components/Symbol/Symbol';
import { formatNumber } from '../../../utils/NumberUtils';

/**
 * props:
 * - data
 * - symbol
 */
function BookRow(props) {

    const [data, setData] = useState({
        bid: '0',  // melhor compra posicionada
        ask: '0',   // melhor venda posicionada
        orderGap: '0'
    })

    function getBigNumber(number) {
        // return number.substring(0, 8);
        return formatNumber(number);
    }

    function getOrderGapClassName(orderGap) {
        let colorClass = '';
        if  (orderGap > 50) colorClass = 'bg-success'; 
        else if  (orderGap > 10) colorClass = 'table-success';
        return 'text-gray-900 text-center ' + colorClass;
    }

    const bookRow = useMemo(() => (
        <tr>
            <td className="text-gray-900"><Symbol symbol={props.symbol} /></td>
            <td className="text-gray-900 text-center">{getBigNumber(data.bid)}</td>
            <td className="text-gray-900 text-center">{getBigNumber(data.ask)}</td>
            <td className={getOrderGapClassName(data.orderGap)}>{formatNumber(data.orderGap, 4)}</td>
        </tr>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [data.bid, data.ask]) // se mudar um desses valores, deve rendererizar novamente o componente

    useEffect(() => {
        if (!props.data) return;

        if (data.bid !== props.data.bestBid)
            data.bid = props.data.bestBid;

        if (data.ask !== props.data.bestAsk)
            data.ask = props.data.bestAsk;

        if (data.orderGap !== props.data.orderGap)
            data.orderGap = props.data.orderGap;

        setData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    return (bookRow);
    // return (<React.Fragment>{JSON.stringify(props.data)}</React.Fragment>)
}

export default BookRow;