import React, { useMemo } from 'react';
import { formatNumber } from '../../utils/NumberUtils';

/**
 * props:
 * - wallet
 * - symbol
 */
function WalletSummary(props) {

    const walletSummary = useMemo(() => (
        <div className='row'>
            <div className='col-md-6 mb-3'>
                <div className='form-group'>
                    <div className='alert alert-success py-1'>
                        {`${props.wallet.base.symbol}: ${formatNumber(props.wallet.base.qty ? props.wallet.base.qty : 0)}`}
                    </div>
                </div>
            </div>
            <div className='col-md-6 mb-3'>
                <div className='form-group'>
                    <div className='alert alert-info py-1'>
                        {`${props.wallet.quote.symbol}: ${formatNumber(props.wallet.quote.qty ? props.wallet.quote.qty : 0)}`}
                    </div>
                </div>
            </div>
        </div>
    ), [props.wallet.base, props.wallet.quote]);

    return walletSummary;
}

export default WalletSummary;