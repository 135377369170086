import React from 'react';
import { getRunButtonId } from '../../utils/DataRowButtonUtils';

/**
 * props:
 * - id
 * - title
 * - onClick
 * - showButton
 */
function RowRunButton(props) {
    return (
        <React.Fragment>
            {
                props.showButton || props.showButton === undefined
                    ? <button id={getRunButtonId(props.id)} type='button' className='btn btn-success btn-xs ms-2' title={props.title} onClick={props.onClick}>
                        <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    </button>
                    : <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    )
}

export default RowRunButton;