import React, { useRef, useMemo, useEffect } from 'react';
import { getEventObject } from '../../../utils/EventUtils';
import { monitorTypesSelect } from '../../../utils/MonitorUtils';

/**
 * props:
 * - type
 * - disabled
 * - onChange
 */
function MonitorType(props) {

    const selectRef = useRef('');

    useEffect(() => {
        selectRef.current.value = props.type;
        props.onChange(getEventObject('type', props.type));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    useEffect(() => {
        if (props.disabled === undefined) return;
        
        selectRef.current.disabled = props.disabled;
    }, [props.disabled]);

    const selectType = useMemo(() => {
        return (
            <div className='form-group'>
                <label htmlFor='type'>Type:</label>
                <select id='type' ref={selectRef} className='form-select' onChange={props.onChange}>
                    {
                        Object.entries(monitorTypesSelect).map(prop => (<option key={prop[0]} value={prop[0]}>{prop[1]}</option>))
                    }
                </select>
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    // disabled={true}
    // <option value='BOOK'>Book</option>
    // <option value='MINI_TICKER'>Mini-Ticker</option>
    // <option value='USER_DATA'>User Data</option>

    return selectType;
}

export default MonitorType;