import React, { useRef, useMemo, useEffect } from 'react';
import { getEventObject } from '../../../utils/EventUtils';
import { intervalTypesSelect, intervalTypes } from '../../../utils/IntervalUtils';

/**
 * props:
 * - interval
 * - onChange
 */
function SelectInterval(props) {

    const selectRef = useRef('');

    useEffect(() => {
        selectRef.current.value = props.interval;
        props.onChange(getEventObject('interval', props.interval));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.interval]);

    const selectInterval = useMemo(() => {
        return (
            <div className='form-group'>
                <label htmlFor='interval'>Interval:</label>
                <select id='interval' ref={selectRef} className='form-select' onChange={props.onChange}>
                    {
                        Object.entries(intervalTypesSelect).map(prop => (<option key={prop[0]} value={intervalTypes[prop[0]]}>{prop[1]}</option>))
                    }
                </select>
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.interval]);

    return selectInterval;
}

export default SelectInterval;