import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cancelOrder, syncOrder } from '../../services/OrdersService';
import { processError } from '../../services/ServiceUtils';
import ActionMessage from '../../components/ActionMessage/ActionMessage';
import { orderStatus, FINISHED_STATUS } from '../../utils/OrderUtils';
import { getEventObject } from '../../utils/EventUtils';
import { getDateFormattedMedium } from '../../utils/DateUtils';
import { formatNumber } from '../../utils/NumberUtils';
import { initializeModal } from '../../utils/ModalUtils';

/**
 * props:
 * - data
 * - onCancelOrder
 * - onClose
 */
function ViewOrderModal(props) {

    const DEFAULT_ORDER = {
        symbol: ''
    };

    const history = useHistory();

    const [error, setError] = useState('');
    const [order, setOrder] = useState(DEFAULT_ORDER);

    const [isSyncing, setIsSyncing] = useState(false);

    const [reloadPage, setReloadPage] = useState(false);

    useEffect(() => {
        if (props.data) {
            setOrder(props.data);

            // btnCancelOrder.current.disabled = props.data.status !== orderStatus.NEW;

            if (btnCancelOrder.current)
                btnCancelOrder.current.disabled = props.data.status !== orderStatus.NEW;

            if (btnSyncOrder.current)
                btnSyncOrder.current.disabled = FINISHED_STATUS.indexOf(props.data.status) !== -1;

        }
    }, [props.data])

    const btnClose = useRef('');
    const btnCancelOrder = useRef('');
    const btnSyncOrder = useRef('');

    function getStatusClass(status) {
        switch (status) {
            case orderStatus.PARTIALLY_FILLED: return 'badge bg-info';
            case orderStatus.FILLED: return 'badge bg-success';
            case orderStatus.REJECTED:
            case orderStatus.EXPIRED:
            case orderStatus.CANCELED: return 'badge bg-danger';
            default: return 'badge bg-primary';
        }
    }

    function getDate(timestamp) {
        if (!timestamp) return '';
        timestamp = parseInt(timestamp);
        // return timestamp;
        const date = new Date(timestamp);
        return getDateFormattedMedium(date);
    }

    function onSyncOrderClick(event) {
        setIsSyncing(true);
        setReloadPage(true);
    }

    function closeModal() {
        btnClose.current.click();
        if (props.onClose) props.onClose(reloadPage);
    }

    useEffect(() => {
        if (!isSyncing) return;

        syncOrder(order.id)
            .then(updatedOrder => {
                setIsSyncing(false);
                setOrder(updatedOrder);
            })
            .catch(err => {
                const errorMessage = processError(err);
                setError(errorMessage);
                setIsSyncing(false);
            })
    }, [isSyncing]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        initializeModal('modalViewOrder', () => setOrder({ ...DEFAULT_ORDER }));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    function onCancelOrderClick(event) {
        cancelOrder(order.symbol, order.orderId)
            .then(result => {
                setReloadPage(true);
                closeModal();
                if (props.onCancelOrder) props.onCancelOrder(getEventObject('order', order.orderId));
                return history.push('/orders/' + order.symbol);
            })
            .catch(err => {
                setError(processError(err));
                // if (err.response && err.response.status === 401) {
                //     closeModal();
                // }
            })
    }

    return (
        <div className="modal fade" id="modalViewOrder" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">Order Details</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <b>Symbol:</b> {order.symbol}
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <span className={getStatusClass(order.status)}>{order.status}</span>
                                    {
                                        order.isMaker
                                            ? <span className='badge bg-warning' title='MAKER'>M</span>
                                            : <React.Fragment></React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <b>Beholder ID:</b> {order.id}
                                </div>
                                {
                                    order.automationId
                                        ? (
                                            <div className='col-md-6 mb-3'>
                                                <b>Automation:</b> {order.automation ? order.automation.name : ''}
                                            </div>
                                        )
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <b>Binance IDs:</b> {order.orderId} / {order.clientOrderId}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <b>Side:</b> {order.side}
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <b>Type:</b> {order.type}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <b>Quantity:</b> {order.quantity}
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <b>Unit Price:</b> {order.limitPrice}
                                </div>
                            </div>
                            <div className='row'>
                                {
                                    order.icebergQuantity
                                        ? (
                                            <div className='col-md-6 mb-3'>
                                                <b>Iceberg Qty:</b> {formatNumber(order.icebergQuantity)}
                                            </div>
                                        )
                                        : <React.Fragment></React.Fragment>
                                }
                                {
                                    order.stopPrice
                                        ? (
                                            <div className='col-md-6 mb-3'>
                                                <b>Stop Price:</b> {formatNumber(order.stopPrice)}
                                            </div>
                                        )
                                        : <React.Fragment></React.Fragment>

                                }
                                <div className='col-md-6 mb-3'>
                                    <b>Avg Price:</b> {formatNumber(order.avgPrice)}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <b>Date:</b> {getDate(order.transactTime)}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <b>Commission:</b> {formatNumber(order.commission)}
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <b>Net:</b> {formatNumber(order.net)}
                                </div>
                            </div>
                            {
                                order.obs
                                    ? (
                                        <div className='row'>
                                            <div className='col-md-12 mb-3'>
                                                <b>Obs:</b> {order.obs}
                                            </div>
                                        </div>)
                                    : <React.Fragment></React.Fragment>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-7 py-1" />
                        <button type='button' ref={btnSyncOrder} className='btn btn-info btn-sm' onClick={onSyncOrderClick}>
                            <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                            </svg> {isSyncing ? "Syncing Order..." : "Sync Order"}
                        </button>
                        <button type='button' ref={btnCancelOrder} className='btn btn-danger btn-sm' onClick={onCancelOrderClick}>
                            <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                            </svg> Cancel Order
                        </button>
                    </div>
                </div>

            </div>
        </div>)
}

export default ViewOrderModal;