import React, { useEffect, useRef, useState } from "react";
import { getEventObject } from "../../utils/EventUtils";
import dateUtils from "../../utils/DateUtils";

/**
 * props:
 * - onClick
 * - startDate
 * - endDate
 */
function DateFilter(props) {

    const startDateRef = useRef('');
    const endDateRef = useRef('');

    const [mode, setMode] = useState(dateUtils.MONTH_MODE);

    function onButtonClick(event) {
        sendValues();
    }

    function getInitialStartDate() {
        return dateUtils.getInitialStartDatePickerDate(mode);
    }

    function getInitialEndDate() {
        return dateUtils.getInitialEndDatePickerDate()
    }

    function loadInputs() {
        startDateRef.current.value = props.startDate ? dateUtils.mountDatePickerDateFromDate(props.startDate) : getInitialStartDate();
        endDateRef.current.value = props.endDate ? dateUtils.mountDatePickerDateFromDate(props.endDate) : getInitialEndDate();
    }

    function sendValues(newMode, modeChanged) {
        if (modeChanged === undefined) modeChanged = false;

        const value = {
            modeChanged,
            dateMode: newMode || mode,
            startDate: dateUtils.parseDatePickerDate(startDateRef.current.value, true),
            endDate: dateUtils.parseDatePickerDate(endDateRef.current.value, false)
        };
        props.onClick(getEventObject('dates', value));
    }

    useEffect(() => {
        setMode(props.dateMode);
        loadInputs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.startDate, props.endDate, props.dateMode]);

    useEffect(() => {
        dateUtils.initDatePicker(document.getElementById('startDate'));
        dateUtils.initDatePicker(document.getElementById('endDate'));
        loadInputs();
        if (!props.startDate && !props.endDate)
            sendValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);

    function updateMode(newMode) {
        setMode(newMode);
        startDateRef.current.value = getInitialStartDate();
        endDateRef.current.value = getInitialEndDate();
        sendValues(newMode, true);
    }

    function setDayMode() {
        updateMode(dateUtils.DAY_MODE);
    }

    function setWeekMode() {
        updateMode(dateUtils.WEEK_MODE);
    }

    function setMonthMode() {
        updateMode(dateUtils.MONTH_MODE);
    }

    function getButtonModeClassName(buttonMode) {
        return mode === buttonMode ? 'btn btn-secondary' : 'btn btn-light';
    }

    return (
        <div className="input-group input-group-merge">
            <button type='button' id='btnDay' className={getButtonModeClassName(dateUtils.DAY_MODE)} title='Day mode' onClick={setDayMode}>
                {dateUtils.DAY_MODE}
            </button>
            <button type='button' id='btnDay' className={getButtonModeClassName(dateUtils.WEEK_MODE)} title='Week mode' onClick={setWeekMode}>
                {dateUtils.WEEK_MODE}
            </button>
            <button type='button' id='btnMonth' className={getButtonModeClassName(dateUtils.MONTH_MODE)} title='Month mode' onClick={setMonthMode}>
                {dateUtils.MONTH_MODE}
            </button>

            <span className="input-group-text">
                <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
                </svg>
            </span>
            <input ref={startDateRef} data-datepicker="" className="form-control datepicker-input" id='startDate' type='text' placeholder="dd/mm/yyyy" />
            <input ref={endDateRef} data-datepicker="" className="form-control datepicker-input" id='endDate' type='text' placeholder="dd/mm/yyyy" />
            <button type='button' id='btnFilter' className="btn btn-primary" onClick={onButtonClick}>
                <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" /></svg>
            </button>
        </div>
    )
}

export default DateFilter;