import React, { useState, useEffect } from 'react';
import { formatNumber } from '../../utils/NumberUtils';

/**
 * props:
 * - data
 */
function AutomationReport(props) {

    const [automations, setAutomations] = useState([]);

    useEffect(() => {
        if (!props.data) return;
        setAutomations(props.data);
    }, [props.data]);

    function formattedNumber(number) {
        // return number.toFixed(2);
        return formatNumber(number, 2);
    }

    return (
        <div className="col-sm-12 col-md-6 mb-4">
            <div className="car border-0 shadow">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h2 className="fs-5 fw-bold mb-0">Automations</h2>
                        </div>
                    </div>
                </div>

                <div className="table-responsive divScroll">
                    <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-bottom col-2" scope="col">NAME</th>
                                <th className="border-bottom col-2 text-center" scope="col">EXECS</th>
                                <th className="border-bottom col-2 text-center" scope="col">NET</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                automations.map(item => (
                                    <tr key={item.name}>
                                        <td className='text-gray-900'>{item.name}</td>
                                        <td className='text-gray-900 text-center'>{item.executions}</td>
                                        <td className='text-gray-900 text-center'>{formattedNumber(item.net)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    )
}

export default AutomationReport;