import React from 'react';
import RowViewButton from '../../../components/ActionButtons/RowViewButton';

/**
 * props:
 * - data
 * - onViewClick
 */
function SharedStrategyRow(props) {

    return (
        <tr>
            <td>
                {
                    props.data.symbol
                        ? props.data.symbol
                        : '*'
                }
            </td>
            <td>
                {props.data.name}
            </td>
            <td>
                {props.data.user.name}
            </td>
            <td>
                <RowViewButton id={props.data.id} title='View this Strategy' modalId='modalViewStrategy' onClick={props.onViewClick} />
            </td>
        </tr>
    );
}

export default SharedStrategyRow;