export const FAVORITES_QUOTE = 'FAVORITES';

export const quoteList = {
    FAVORITES: 'Favorites',
    BNB: 'BNB',
    BRL: 'BRL',
    BTC: 'BTC',
    ETH: 'ETH',
    EUR: 'EUR',
    GBP: 'GBP',
    USD: 'USD',
    USDT: 'USDT'
}

export const DEFAULT_QUOTE_PROPERTY = "defaultQuote";
export const DEFAULT_QUOTE = "USD";

export function getFilteredQuoteList(noFavorites) {
    let result = { ...quoteList };
    if (noFavorites)
        delete result[FAVORITES_QUOTE];
    return result;
}

export function filterSymbolObjects(symbols, quote) {
    return symbols.filter(s => {
        if (quote === FAVORITES_QUOTE)
            return s.isFavorite;
        else
            return s.symbol.endsWith(quote);
    });
}

export function filterSymbolNames(symbols, quote) {
    return filterSymbolObjects(symbols, quote).map(s => s.symbol);
}

export function getDefaultQuote() {
    const value = localStorage.getItem(DEFAULT_QUOTE_PROPERTY); 
    return value ? value : DEFAULT_QUOTE;
}

export function setDefaultQuote(quote) {
    localStorage.setItem(DEFAULT_QUOTE_PROPERTY, quote);
}

