import React, { useEffect, useState, useMemo } from 'react';
import Symbol from '../../../components/Symbol/Symbol';
import { formatNumber } from '../../../utils/NumberUtils';

/**
 * props:
 * - data
 * - symbol
 */
function TickerRow(props) {

    const [data, setData] = useState({
        close: '0',
        open: '0',
        high: '0',
        low: '0',
        percentChange: 0
    })

    function getBigNumber(number) {
        // return number.substring(0, 8);
        return formatNumber(number);
    }

    function formatPercent(number) {
        // return number.substring(0, 8);
        return formatNumber(number, 2) + ' %';
    }
    
    function getPercClassName(percentChange) {
        let colorClass = '';
        if  (percentChange > 50) colorClass = 'bg-success'; 
        else if  (percentChange > 10) colorClass = 'table-success';
        else if  (percentChange < -50) colorClass = 'bg-danger';
        else if  (percentChange < -10) colorClass = 'table-danger';

        return 'text-gray-900 text-center ' + colorClass;
    }

    const tickerRow = useMemo(() => (
        <tr>
            <td className="text-gray-900">
                <Symbol symbol={props.symbol} />
            </td>
            <td className="text-gray-900 text-center">
                {getBigNumber(data.close)}
            </td>
            <td className="text-gray-900 text-center">
                {getBigNumber(data.open)}
            </td>
            <td className="text-gray-900 text-center">
                {getBigNumber(data.high)}
            </td>
            <td className="text-gray-900 text-center">
                {getBigNumber(data.low)}
            </td>
            <td className={getPercClassName(data.percentChange)}>
                {formatPercent(data.percentChange)}
            </td>
        </tr>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [data.close, data.open, data.high, data.low, data.percentChange]) // se mudar um desses valores, deve rendererizar novamente o componente

    useEffect(() => {
        if (!props.data) return;

        if (data.close !== props.data.close)
            data.close = props.data.close;

        if (data.open !== props.data.open)
            data.open = props.data.open;

        if (data.high !== props.data.high)
            data.high = props.data.high;

        if (data.low !== props.data.low)
            data.low = props.data.low;

        if (data.percentChange !== props.data.percentChange)
            data.percentChange = props.data.percentChange;

        setData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    return (tickerRow);
}

export default TickerRow;