import React, { useState, useEffect } from 'react';
import { updateSymbol } from '../../services/SymbolsService';
import { processError } from '../../services/ServiceUtils';
import SymbolIcon from '../../components/SymbolIcon/SymbolIcon';
import RowFavoriteButton from '../../components/ActionButtons/RowFavoriteButton';
import RowViewButton from '../../components/ActionButtons/RowViewButton';

/**
 * props.onClick
 * props.data:
 * - symbol
 * - basePrecision
 * - quotePrecision
 * - minNotional
 * - minLotSize
 */
function SymbolRow(props) {

    const [symbol, setSymbol] = useState({});

    useEffect(() => {
        if (!props.data) return;
        setSymbol(props.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.symbol]);

    function onFavoriteClick(event) {
        symbol.isFavorite = !symbol.isFavorite;
        updateSymbol(symbol)
            .then(result => setSymbol({ ...symbol }))
            .catch(err => processError(err));
    }

    return (
        <tr>
            <td className="text-gray-900">
                <SymbolIcon key={symbol.symbol} symbol={symbol.symbol} base={symbol.base} />
            </td>
            <td className="text-gray-900">
                {symbol.basePrecision}
            </td>
            <td className="text-gray-900">
                {symbol.quotePrecision}
            </td>
            <td className="text-gray-900">
                {symbol.minNotional}
            </td>
            <td className="text-gray-900">
                {symbol.minLotSize}
            </td>
            <td>
                <RowViewButton id={symbol.symbol} title='View this Symbol' modalId='modalSymbol' onClick={props.onClick} />
                <RowFavoriteButton id={symbol.symbol} isFavorite={symbol.isFavorite} title='Is this Symbol Favorite?' onClick={onFavoriteClick} />
            </td>
        </tr>
    );
}

export default SymbolRow;