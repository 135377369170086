import React, {useMemo} from 'react';
import { orderSidesSelect } from '../../utils/OrderUtils';

/**
 * props:
 * - side
 * - onChange
 */
function SelectSide(props) {

    const selectSide = useMemo(() => (
        <div className='form-group'>
            <label htmlFor='side'>Side:</label>
            <select id='side' className='form-select' value={props.side} onChange={props.onChange}>
                {
                    Object.entries(orderSidesSelect).map(prop => (<option key={prop[0]} value={prop[0]}>{prop[1]}</option>) )
                }
            </select>
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [props.side]);

    return selectSide;
}

export default SelectSide;