import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';
import { checkFloatValue } from '../utils/NumberUtils';

const ORDERS_TEMPLATES_URL = getBaseUrl('/orderTemplates/');

export async function getOrderTemplates(symbol, page) {
    return await appRequest.doGetRequest(`${ORDERS_TEMPLATES_URL}${symbol}?page=${page}`);
}

export async function getAllOrderTemplates(symbol) {
    return await appRequest.doGetRequest(`${ORDERS_TEMPLATES_URL}all/${symbol}`);
}

export async function saveOrderTemplate(id, newOrderTemplate) {
    newOrderTemplate.quantityMultiplier = checkFloatValue(newOrderTemplate.quantityMultiplier);
    newOrderTemplate.icebergQtyMultiplier = checkFloatValue(newOrderTemplate.icebergQtyMultiplier);
    newOrderTemplate.limitPriceMultiplier = checkFloatValue(newOrderTemplate.limitPriceMultiplier);
    newOrderTemplate.stopPriceMultiplier = checkFloatValue(newOrderTemplate.stopPriceMultiplier);

    if (id)
        return await appRequest.doPatchRequest(`${ORDERS_TEMPLATES_URL}${id}`, newOrderTemplate);
    else
        return await appRequest.doPostRequest(ORDERS_TEMPLATES_URL, newOrderTemplate);
}

export async function deleteOrderTemplate(id) {    
    return await appRequest.doDeleteRequest(`${ORDERS_TEMPLATES_URL}${id}`);
}

