const actionTypesSelect = {
    ALERT_EMAIL: 'Alert via Email',
    ALERT_SMS: 'Alert via SMS',
    ALERT_TELEGRAM: 'Alert via Telegram',
    ORDER: 'Place Order',
    TRAILING: 'Place Trailing',
    WITHDRAW: 'Withdraw Crypto'
}

const actionTypes = {
    ALERT_EMAIL: 'ALERT_EMAIL',
    ALERT_SMS: 'ALERT_SMS',
    ALERT_TELEGRAM: 'ALERT_TELEGRAM',
    ORDER: 'ORDER',
    WITHDRAW: 'WITHDRAW',
    TRAILING: 'TRAILING',
    GRID: 'GRID'
}

module.exports = {
    actionTypesSelect,
    actionTypes
}