import React from 'react';
import RowViewButton from '../../components/ActionButtons/RowViewButton';
import AutomationIcon from '../../components/AutomationIcon/AutomationIcon';
import { getDateFormattedComplete } from '../../utils/DateUtils';
import { formatNumber } from '../../utils/NumberUtils';
import { orderSides, orderStatus } from '../../utils/OrderUtils';

/**
 * props:
 * - data
 * - onClick
 */
function OrderRow(props) {

    function getDate(timestamp) {
        // timestamp = timestamp * 1; // para forçar conversão pra número. sem essa linha dá pau na data
        // timestamp = parseInt(timestamp);
        timestamp = Number(timestamp);
        const date = new Date(timestamp);
        return getDateFormattedComplete(date);
    }
    
    function getStatus(status) {
        let className;
        switch (status) {
            case orderStatus.PARTIALLY_FILLED: className = 'badge bg-info py-1'; break;
            case orderStatus.FILLED: className = 'badge bg-success py-1'; break;
            case orderStatus.REJECTED:
            case orderStatus.EXPIRED:
            case orderStatus.CANCELED: className = 'badge bg-danger py-1'; break;
            default: className = 'badge bg-warning py-1'; break;
        }
        return (<span className={className}>{status.split('_')[0]}</span>)
    }
    
    function getSideClassName(side) {
        const sideColor = side === orderSides.BUY ? 'bg-warning' : 'bg-success';
        return `badge ${sideColor} py-1 me-2`;
    }

    // <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" /></svg>

    return (
        <tr>
            <td>
                {
                    props.data.automationId
                        ? (
                            <AutomationIcon automation={props.data.automation} />
                        )
                        : (
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 3a1 1 0 012 0v5.5a.5.5 0 001 0V4a1 1 0 112 0v4.5a.5.5 0 001 0V6a1 1 0 112 0v5a7 7 0 11-14 0V9a1 1 0 012 0v2.5a.5.5 0 001 0V4a1 1 0 012 0v4.5a.5.5 0 001 0V3z" clipRule="evenodd" /></svg>
                        )
                }
                {
                    <span className={getSideClassName(props.data.side)}>{props.data.side}</span>

                }
                {props.data.symbol}
            </td>
            <td className='text-center'><span className="fw-normal">{getDate(props.data.transactTime)}</span></td>
            <td className='text-center'><span className='fw-normal'>{formatNumber(props.data.quantity)}</span></td>
            <td className='text-center'><span className='fw-bold'>{formatNumber(props.data.net)}</span></td>
            <td className='text-center'>{getStatus(props.data.status)}</td>
            <td className='text-center'>
            <RowViewButton id={props.data.id} title='View this Order' modalId='modalViewOrder' onClick={props.onClick} />
            </td>
        </tr>
    )
}

export default OrderRow;