import React, { useRef, useState, useEffect } from 'react';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import SelectSymbol from '../../../components/SelectSymbol/SelectSymbol';
import MonitorType from './MonitorType';
import SelectInterval from './SelectInterval';
import MonitorIndex from './MonitorIndex';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import { saveMonitor } from '../../../services/MonitorsService';
import { processError } from '../../../services/ServiceUtils';
import LogButton from '../../../components/Logs/LogButton';
import LogView from '../../../components/Logs/LogView';
import { monitorTypes } from '../../../utils/MonitorUtils';
import { intervalTypes } from '../../../utils/IntervalUtils';
import { DEFAULT_SYMBOL } from '../../../utils/SymbolUtils';
import { initializeModal } from '../../../utils/ModalUtils';

/**
 * props:
 * - data
 * - onSubmit
 */
function MonitorModal(props) {

    const DEFAULT_MONITOR = {
        id: null,
        isSystemMon: false,
        symbol: DEFAULT_SYMBOL,
        type: monitorTypes.CANDLES,
        interval: intervalTypes.INT_1m,
        isActive: false,
        logs: false,
        userId: 0
    };

    const [error, setError] = useState('');
    const [monitor, setMonitor] = useState(DEFAULT_MONITOR);
    const [showLogs, setShowLogs] = useState(false);

    const btnClose = useRef('');
    const btnSave = useRef('');

    useEffect(() => {
        if (!props.data || !props.data.id)
            setMonitor({ ...DEFAULT_MONITOR });
        else
            setMonitor(props.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    }, [props.data]);

    function onSubmit(event) {
        saveMonitor(monitor.id, monitor)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)))
        // props.onSubmit(event);
    }

    function onInputChange(event) {
        setMonitor(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function getIntervalClasses(monitorType) {
        return monitorType === monitorTypes.CANDLES ? 'col-md-6 mb-3' : 'col-md-6 mb-3 d-none';
    }

    function onLogClick(event) {
        setShowLogs(!showLogs);
    }

    useEffect(() => {
        initializeModal('modalMonitor', () => setMonitor({ ...DEFAULT_MONITOR }));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <div className="modal fade" id="modalMonitor" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Monitor</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <MonitorType type={monitor.type} onChange={onInputChange} disabled={true} />
                                </div>
                                {
                                    monitor.type === monitorTypes.CANDLES
                                        ? (
                                            <div className='col-md-6 mb-3'>
                                                <div className="form-group mb-3">
                                                    <label htmlFor='symbol'>Symbol</label>
                                                    <SelectSymbol onChange={onInputChange} symbol={monitor.symbol} disabled={monitor.id > 0} />
                                                </div>
                                            </div>
                                        )
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                            {
                                showLogs
                                    ? <LogView file={'M_' + monitor.id} />
                                    : (
                                        <React.Fragment>

                                            <div className='row'>
                                                {/* <div className='col-md-6 mb-3'>
                                                    <div className='form-group'>
                                                        <label htmlFor='broadcastLabel'>Broadcast Label: <span data-bs-toggle='tooltip' data-bs-placement='top' title='Label to broadcast the info via WebSockets' className='badge bg-warning py-1'>?</span></label>
                                                        <input type='text' id='broadcastLabel' className='form-control' onChange={onInputChange} value={getValueOrDefault(monitor.broadcastLabel)} placeholder='none' />
                                                    </div>
                                                </div> */}
                                                {
                                                    monitor.type === monitorTypes.CANDLES
                                                        ? (<React.Fragment>
                                                            <div className={getIntervalClasses(monitor.type)}>
                                                                <SelectInterval interval={monitor.interval} onChange={onInputChange} />
                                                            </div>
                                                            <MonitorIndex indexes={monitor.indexes} onChange={onInputChange} />
                                                        </React.Fragment>
                                                        )
                                                        : <React.Fragment></React.Fragment>
                                                }
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <div className='form-group'>
                                                        <SwitchInput id='isActive' text='Is Active?' onChange={onInputChange} isChecked={monitor.isActive} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <div className='form-group'>
                                                        <SwitchInput id='logs' text='Enable Logs?' onChange={onInputChange} isChecked={monitor.logs} />
                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    )
                            }

                        </div>

                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <LogButton id={monitor.id} onClick={onLogClick} />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                            Save
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default MonitorModal;