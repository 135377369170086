
const quantityTypes = {
    MIN_NOTIONAL: 'MIN_NOTIONAL',
    MAX_WALLET: 'MAX_WALLET',
    LAST_ORDER_QTY: 'LAST_ORDER_QTY',
    QUOTE_QTY: 'QUOTE_QTY'
}

const quantityTypeNames = {
    MIN_NOTIONAL: 'Min. Notional',
    MAX_WALLET: 'Max. Wallet',
    LAST_ORDER_QTY: 'Last Order Qty.',
    QUOTE_QTY: 'Quote Qty.'
}

function getQuantityValue(quantityName) {
    if (quantityName === quantityTypeNames.MAX_WALLET)
        return quantityTypes.MAX_WALLET;
    else if (quantityName === quantityTypeNames.MIN_NOTIONAL)
        return quantityTypes.MIN_NOTIONAL;
    else if (quantityName === quantityTypeNames.LAST_ORDER_QTY)
        return quantityTypes.LAST_ORDER_QTY;
    else if (quantityName === quantityTypeNames.QUOTE_QTY)
        return quantityTypes.QUOTE_QTY;
    else
        return quantityName;
}

function getQuantityName(quantityValue) {
    if (quantityValue === quantityTypes.MAX_WALLET)
        return quantityTypeNames.MAX_WALLET;
    else if (quantityValue === quantityTypes.MIN_NOTIONAL)
        return quantityTypeNames.MIN_NOTIONAL;
    else if (quantityValue === quantityTypes.LAST_ORDER_QTY)
        return quantityTypeNames.LAST_ORDER_QTY;
    else if (quantityValue === quantityTypes.QUOTE_QTY)
        return quantityTypeNames.QUOTE_QTY;
    else
        return quantityValue;
}

module.exports = {
    quantityTypes,
    quantityTypeNames,

    getQuantityValue,
    getQuantityName
}
