import React from 'react';
import RowDeleteButton from '../../../components/ActionButtons/RowDeleteButton';
import RowEditButton from '../../../components/ActionButtons/RowEditButton';
import RowStartButton from '../../../components/ActionButtons/RowStartButton';
import RowStopButton from '../../../components/ActionButtons/RowStopButton';

/**
 * props:
 * - data
 * - onEditClick
 * - onStopClick
 * - onStartClick
 * - onDeleteClick
 */
function MyStrategyRow(props) {

    function getStatusClass(isActive) {
        return isActive ? 'text-success' : 'text-danger';
    }

    function getStatusText(isActive) {
        return isActive ? 'RUNNING' : 'STOPPED';
    }

    return (
        <tr>
            <td>
                {
                    props.data.symbol
                        ? props.data.symbol
                        : '*'
                }
            </td>
            <td>
                {
                    props.data.name
                }
            </td>
            <td>
                <span className={getStatusClass(props.data.isActive)}>{getStatusText(props.data.isActive)}</span>
            </td>
            <td>
                <RowEditButton id={props.data.id} title='Edit this Strategy' modalId='modalStrategy' onClick={props.onEditClick} />
                <RowStopButton id={props.data.id} title='Stop this Strategy' onClick={props.onStopClick} showButton={props.data.isActive} />
                <RowStartButton id={props.data.id} title='Start this Strategy' onClick={props.onStartClick} showButton={!props.data.isActive} />
                <RowDeleteButton id={props.data.id} title='Delete this Strategy' onClick={props.onDeleteClick} showButton={!props.data.isActive} />
            </td>
        </tr>
    );
}

export default MyStrategyRow;