import React, { useState, useEffect } from 'react';
import { getFilteredQuoteList, getDefaultQuote } from '../../utils/QuoteUtils';

/**
 * props:
 * - onChange
 * - noFavorites
 * - disabled
 * - value
 * - forSelectSymbol
 */
function SelectQuote(props) {

    const [quote, setQuote] = useState(props ? props.value : '');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (!props.value) {
            setQuote(getDefaultQuote());
            return;
        }
        setQuote(props.value);
    }, [props.value]);

    useEffect(() => {
        setIsDisabled(props.disabled);
    }, [props.disabled]);

    // <select id="selectQuote" className="form-select" disabled={isDisabled} {... props.forSelectSymbol ? {value: quote} : {defaultValue: quote}} onChange={props.onChange}>

    return (
        <select id="selectQuote" className="form-select" disabled={isDisabled} value={quote} onChange={props.onChange}>
            {
                Object.entries(getFilteredQuoteList(props.noFavorites)).map(prop => (
                    <option key={prop[0]} value={prop[0]}>{prop[1]}</option>
                ))
            }
        </select>
    )
}

export default SelectQuote;