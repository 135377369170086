import React from 'react';
import RowDeleteButton from '../../components/ActionButtons/RowDeleteButton';
import RowEditButton from '../../components/ActionButtons/RowEditButton';
import CopyButton from '../../components/CopyButton/CopyButton';
import { generateWebHookUrl } from '../../utils/WebHookUtils';

/**
 * props:
 * - data
 * - onEditClick
 * - onDeleteClick
 */
function WebHookRow(props) {

    function getWebKookUrl() {
        return generateWebHookUrl(props.data.userId, props.data.key);
    }

    return (
        <tr>
            <td>
                {
                    props.data.symbol
                        ? props.data.symbol
                        : '*'
                }
            </td>
            <td>
                {props.data.name}
            </td>
            <td>
                <CopyButton text={getWebKookUrl()} title='Copy the WebHook URL' />
                <RowEditButton id={props.data.id} title='Edit this WebHook' modalId='modalWebHook' onClick={props.onEditClick} />
                <RowDeleteButton id={props.data.id} title='Delete this WebHook' onClick={props.onDeleteClick} />
            </td>
        </tr>
    );
}

export default WebHookRow;