import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import { getOrders } from '../../services/OrdersService';
import { processError } from '../../services/ServiceUtils';
import OrderRow from './OrderRow';
// import Pagination from '../../components/Pagination/Pagination';
import SearchSymbol from '../../components/SearchSymbol/SearchSymbol';
import ViewOrderModal from './ViewOrderModal';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import Pager from '../../components/Pager/Pager';
import { findRowFromActionButton } from '../../utils/DataRowButtonUtils';

function Orders() {

    const { symbol } = useParams();

    const [search, setSearch] = useState(symbol || '');
    const [viewOrder, setViewOrder] = useState({});

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    const history = useHistory();

    const [orders, setOrders] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(parseInt(getPage()));

    const [notification, setNotification] = useState(getEmptyMessage());

    function getOrdersCall() {
        getOrders(search, page || 1)
            .then(result => {
                setOrders(result.rows);
                setCount(result.count);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    useEffect(() => {
        getOrdersCall();
    }, [page, search]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        })
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    function onCloseViewOrderModal(reloadPage) {
        if (reloadPage)
            history.go(0); // recarrega a tela
    }

    function onOrderSubmit(order, reloadPage) {
        if (reloadPage)
            history.go(0); // recarrega a tela
    }

    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    function onViewClick(event) {
        setViewOrder(findRowFromActionButton(event, orders));
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Orders</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <NewOrderButton />
                        </div>
                        <div className='btn-group ms-2 ms-lg-3'>
                            <SearchSymbol placeholder="Search symbol" onChange={onSearchChange} />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Order</th>
                                <th className='border-gray-200 text-center'>Date</th>
                                <th className='border-gray-200 text-center'>Qty</th>
                                <th className='border-gray-200 text-center'>Net</th>
                                <th className='border-gray-200 text-center'>Status</th>
                                <th className='border-gray-200 text-center'>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders && orders.length
                                    ? orders.map(order => (
                                        <OrderRow key={order.clientOrderId} data={order} onClick={onViewClick} />
                                    ))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    {/* <Pagination count={count} /> */}
                    <Pager count={count} page={page} size={10} onClick={setPage} />
                </div>
                <Footer />
            </main>
            <ViewOrderModal data={viewOrder} onClose={onCloseViewOrderModal} />
            <NewOrderModal onSubmit={onOrderSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default Orders;