import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const STRATEGIES_URL = getBaseUrl('/strategies/');

export async function getStrategies(symbol = '', page = 1) {
    return await appRequest.doGetRequest(`${STRATEGIES_URL}?page=${page}&symbol=${symbol}`);
}

export async function getAllStrategies(symbol, onlyActives) {
    const params = {};
    if (symbol) params.symbol = symbol;
    if (onlyActives) params.onlyActives = onlyActives;
    const requestParams = new URLSearchParams(params).toString();

    return await appRequest.doGetRequest(`${STRATEGIES_URL}all/?${requestParams}`);
}

export async function getSharedStrategies(symbol = '', includePublic = true, page = 1) {
    return await appRequest.doGetRequest(`${STRATEGIES_URL}shared?page=${page}&symbol=${symbol}&public=${includePublic}`);
}

export async function saveStrategy(id, newStrategy) {
    if (id)
        return await appRequest.doPatchRequest(`${STRATEGIES_URL}${id}`, newStrategy);
    else
        return await appRequest.doPostRequest(STRATEGIES_URL, newStrategy);
}

export async function deleteStrategy(id) {
    return await appRequest.doDeleteRequest(`${STRATEGIES_URL}${id}`);
}

export async function startStrategy(id) {
    return await appRequest.doPostRequest(`${STRATEGIES_URL}${id}/start`, {});
}

export async function stopStrategy(id) {
    return await appRequest.doPostRequest(`${STRATEGIES_URL}${id}/stop`, {});
}

export async function copyStrategy(id) {
    return await appRequest.doPostRequest(`${STRATEGIES_URL}${id}/copy`, {});
}
