import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';
import { orderTypes, STOP_TYPES } from '../utils/OrderUtils';
import dateUtils from '../utils/DateUtils';

const ORDERS_URL = getBaseUrl('/orders/');

export async function getOrders(symbol, page) {
    return await appRequest.doGetRequest(`${ORDERS_URL}${symbol}?page=${page}`);
}

export async function cancelOrder(symbol, orderId) {
    return await appRequest.doDeleteRequest(`${ORDERS_URL}${symbol}/${orderId}`);
}

export async function syncOrder(beholderOrderId) {
    return await appRequest.doPostRequest(`${ORDERS_URL}${beholderOrderId}/sync`, null);
}

export async function placeOrder(order) {
    const postOrder = {
        symbol: order.symbol,
        quantity: order.quantity,
        side: order.side.toUpperCase(),
        options: {
            type: order.type.toUpperCase()
        }
    };

    if (postOrder.options.type === orderTypes.MARKET && order.isQuote) {
        postOrder.options.quoteOrderQty = order.quantity;
        delete postOrder.quantity;
    }

    // Ordens aonde o preço precisa ter valor definido
    if ([orderTypes.LIMIT, orderTypes.STOP_LOSS_LIMIT, orderTypes.TAKE_PROFIT_LIMIT, orderTypes.TRAILING_STOP].includes(postOrder.options.type))
        postOrder.limitPrice = order.limitPrice;

    if (STOP_TYPES.includes(postOrder.options.type))
        postOrder.options.stopPrice = order.stopPrice;

    if (postOrder.options.type === orderTypes.TRAILING_STOP)
        postOrder.options.stopPriceMultiplier = order.stopPriceMultiplier;

    return await appRequest.doPostRequest(ORDERS_URL, postOrder);
}

export async function getOrdersReport(symbol, automationId, startDate, endDate) {
    startDate = startDate ? startDate.getTime() : dateUtils.getThirtyDaysAgo();
    endDate = endDate ? endDate.getTime() : dateUtils.getTodayEnd();

    const params = { startDate, endDate };
    if (automationId) params.automationId = automationId;

    // const requestParams = `startDate=${startDate}&endDate=${endDate}`;
    const requestParams = new URLSearchParams(params).toString();

    return await appRequest.doGetRequest(`${ORDERS_URL}reports/${symbol}?${requestParams}`);
}

export async function getDayTradeReport(symbol, automationId, date) {
    date = date ? date.getTime() : dateUtils.getTodayStart();

    const params = { date };
    if (automationId) params.automationId = automationId;

    // const requestParams = `date=${date}`;
    const requestParams = new URLSearchParams(params).toString();

    return await appRequest.doGetRequest(`${ORDERS_URL}reports/${symbol}?${requestParams}`);
}