import React, { useRef, useState, useEffect } from 'react';
import ActionMessage from '../../components/ActionMessage/ActionMessage';
import CopyButton from '../../components/CopyButton/CopyButton';
import SelectSymbol from '../../components/SelectSymbol/SelectSymbol';
import { processError } from '../../services/ServiceUtils';
import { saveWebHook } from '../../services/WebHooksService';
import { getValueOrDefault, initializeModal } from '../../utils/ModalUtils';
import { DEFAULT_SYMBOL } from '../../utils/SymbolUtils';
import { generateWebHookUrl } from '../../utils/WebHookUtils';

/**
 * props:
 * - data
 * - onSubmit
 */
function WebHookModal(props) {

    const DEFAULT_WEBHOOK = {
        id: null,
        symbol: DEFAULT_SYMBOL,
        key: '',
        userId: 0,
        name: '',
        host: '*'
    };

    const [error, setError] = useState('');
    const [webHook, setWebHook] = useState(DEFAULT_WEBHOOK);

    const btnClose = useRef('');
    const btnSave = useRef('');

    useEffect(() => {
        if (!props.data || !props.data.id) return;

        setWebHook(props.data);
    }, [props.data]);

    function onSubmit(event) {
        saveWebHook(webHook.id, webHook)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => setError(processError(err)))
        props.onSubmit(event);
    }

    function onInputChange(event) {
        setWebHook(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function getWebHookUrl() {
        if (!webHook.key) return `It will be generated after save. Come back later.`;
        return generateWebHookUrl(webHook.userId, webHook.key);
    }

    useEffect(() => {
        initializeModal('modalWebHook', () => setWebHook({ ...DEFAULT_WEBHOOK }));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <div className="modal fade" id="modalWebHook" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}WebHook</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-md-7 mb-3'>
                                <div className="form-group">
                                    <label htmlFor='symbol'>Symbol</label>
                                    <SelectSymbol onChange={onInputChange} symbol={webHook.symbol} disabled={!!webHook.key} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='name'>Name:</label>
                                    <input type='text' id='name' className='form-control' onChange={onInputChange} value={getValueOrDefault(webHook.name)} placeholder="WebHook's name" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='host'>Authorized Host:</label>
                                    <input type='text' id='host' className='form-control' onChange={onInputChange} value={getValueOrDefault(webHook.host)} placeholder="*" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='url'>Post URL:</label>
                                    <input type='text' id='url' disabled={true} className='form-control' value={getWebHookUrl()} />
                                    {
                                        webHook.key
                                        ? <CopyButton text={getWebHookUrl()} title='Copy the WebHook URL' />
                                        : <React.Fragment></React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" /></svg>
                            Save
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default WebHookModal;