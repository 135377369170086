import React from 'react';
import RowDeleteButton from '../../components/ActionButtons/RowDeleteButton';
import RowEditButton from '../../components/ActionButtons/RowEditButton';
import RowRunButton from '../../components/ActionButtons/RowRunButton';

/**
 * props:
 * - data
 * - onEditClick
 * - onRunClick
 * - onDeleteClick
 */
function WithdrawTemplateRow(props) {

    return (
        <tr>
            <td>
                {props.data.coin}
            </td>
            <td>
                {props.data.name}
            </td>
            <td>
                <span className='fw-normal'>{props.data.amount}</span>
            </td>
            <td>
                <RowEditButton id={props.data.id} title='Edit this Withdraw Template' modalId='modalWithdrawTemplate' onClick={props.onEditClick} />
                <RowRunButton id={props.data.id} title='Withdraw Now' onClick={props.onRunClick} />
                <RowDeleteButton id={props.data.id} title='Delete this Withdraw Template' onClick={props.onDeleteClick} />
            </td>
        </tr>
    );
}

export default WithdrawTemplateRow;