import React, { useState, useEffect } from 'react';
import { getAutomationsBySymbol } from '../../../services/AutomationsService';
import { processError } from '../../../services/ServiceUtils';

/**
 * props:
 * - symbol
 * - id
 * - automationId
 * - onChange
 */
function SelectAutomation(props) {

    const [automations, setAutomations] = useState([]);

    useEffect(() => {
        if (!props.symbol) return setAutomations([]);
        
        getAutomationsBySymbol(props.symbol)
            .then(result => setAutomations(result))
            .catch(err => setAutomations([processError(err)]));
    }, [props.symbol]);

    function onAutomationChange(event) {
        if (props.onChange)
            props.onChange(event);
    }

    return (
        <select className='form-select' id={props.id ? props.id : 'automationId'} value={props.automationId} onChange={onAutomationChange}>
            <option value='0'>Select...</option>
            {
                automations
                    ? automations.map(a => (<option key={a.id} value={a.id}>{a.name}</option>))
                    : <option>NO AUTOMATIONS</option>
            }
        </select>
    )
}

export default SelectAutomation;