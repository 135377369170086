import React from 'react';
import SymbolIcon from '../../../components/SymbolIcon/SymbolIcon';
import { formatNumber } from '../../../utils/NumberUtils';

/**
 * props:
 * - available
 * - onOrder
 * - symbol
 */
function WalletRow(props) {

    if (!parseFloat(props.available) && !parseFloat(props.onOrder)) return <React.Fragment></React.Fragment>;


    function formatBigNumber(number) {
        // return number.substring(0, 10);
        return formatNumber(number);
    }

    return (
        <tr>
            <td className="text-gray-900">
                <SymbolIcon key={props.symbol} symbol={props.symbol} base={props.symbol} />
            </td>
            <td className="text-gray-900 text-center">{formatBigNumber(props.available)}</td>
            <td className="text-gray-900 text-center">{formatBigNumber(props.onOrder)}</td>
        </tr>
    )
}

export default WalletRow;