import React, { useEffect, useState } from 'react';
import { actionTypesSelect } from '../../../../utils/ActionUtils';

/**
 * props:
 * - type
 * - onChange
 */
function ActionType(props) {

    const [type, setType] = useState('');

    useEffect(() => {
        setType(props.type);
    }, [props.type])

    return (
        <select id="type" className='form-select' value={type} onChange={props.onChange}>
            {
                Object.entries(actionTypesSelect).map(prop => (<option key={prop[0]} value={prop[0]}>{prop[1]}</option>))
            }
        </select>
    )
}

export default ActionType;
