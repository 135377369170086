import React from 'react';
import SymbolIcon from '../../components/SymbolIcon/SymbolIcon';
import { formatNumber } from '../../utils/NumberUtils';

/**
 * props:
 * - data
 */
function WalletRow(props) {

    function formatBigNumber(number) {
        // return number.substring(0, 10);
        return formatNumber(number);
    }

    function formatSmallNumber(number) {
        // return number.toFixed(2);
        return formatNumber(number, 2);
    }

    return (
        <tr>
            <td>
                <SymbolIcon key={props.data.symbol} symbol={props.data.symbol} base={props.data.symbol} />
            </td>
            <td className='text-center'>
                {formatBigNumber(props.data.available)}
            </td>
            <td className='text-center'>
                {parseFloat(props.data.onOrder) > 0 ? formatBigNumber(props.data.onOrder) : '-'}
            </td>
            <td className='text-end'>
                {props.data.fiatEstimate ? formatSmallNumber(props.data.fiatEstimate) : '-'}
            </td>
            <td className='text-end'>
                {props.data.avg ? formatSmallNumber(props.data.avg) : '-'}
            </td>
        </tr >
    );
}

export default WalletRow;