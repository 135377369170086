import React from "react";

/**
 * props
 * - symbol
 */
function Symbol(props) {

    // const URL = 'https://www.binance.com/pt-BR/trade/';
    const URL = 'https://br.tradingview.com/symbols/';

    return (
        <a href={URL+props.symbol} target="_blank" rel="noreferrer" className="text-white-900 badge bg-info text-white text-bold" style={{fontSize: '0.875rem'}}>{props.symbol}</a>
    )
}

export default Symbol;