import React, { useEffect, useState } from 'react';
import { getDateFormattedShort } from '../../../utils/DateUtils';
import { orderSides } from '../../../utils/OrderUtils';

/**
 * props:
 * - data
 */
function ResultsTable(props) {

    const [backtest, setBacktest] = useState({});

    function getCoin(coin) {
        return coin && typeof coin === 'number' ? coin.toFixed(5) : coin;
    }

    function getPerc(value) {
        if (!value) return <React.Fragment></React.Fragment>;

        let bg;
        const perc = value && typeof value === 'number' ? value.toFixed(4) : value;
        
        if (value > 0) {
            value = '+' + perc + '%';
            bg = 'badge bg-success py-1 ms-1';
        }
        else if (value < 0) {
            value = perc + '%';
            bg = 'badge bg-danger py-1 ms-1';
        }
        else {
            value = '0%';
            bg = 'badge bg-secondary py-1 ms-1';
        }

        return <span className={bg}>{value}</span>;
    }

    function getItem(order) {
        const date = new Date(order.transactTime);
        return getDateFormattedShort(date);
    }

    useEffect(() => {
        if (!props.data) return;
        setBacktest(props.data);
    }, [props.data]);

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-md-6 mb-3'>
                    <b>End Base ({backtest.base}): </b> <br /> {getCoin(backtest.endBase)} {getPerc(backtest.basePerc)}
                </div>
                <div className='col-md-6 mb-3'>
                    <b>End Quote ({backtest.quote}): </b> <br /> {getCoin(backtest.endQuote)} {getPerc(backtest.quotePerc)}
                </div>
            </div>
            <div className='row divBacktestResults'>
                <div className='col-md-6 mb-3'>
                    <li className='list-group-item list-group-item-warning'>BUY ({backtest.buys})</li>
                    {
                        backtest.results && backtest.results.length && backtest.results.filter(r => r.side === orderSides.BUY).map(order => (
                            <li key={'o' + order.transactTime} className='list-group-item'>{getItem(order)}</li>
                        ))
                    }
                </div>
                <div className='col-md-6 mb-3'>
                    <li className='list-group-item list-group-item-success'>SELL ({backtest.sells})</li>
                    {
                        backtest.results && backtest.results.length && backtest.results.filter(r => r.side === orderSides.SELL).map(order => (
                            <li key={'o' + order.transactTime} className='list-group-item'>{getItem(order)}</li>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default ResultsTable;