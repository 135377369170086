import React, { useMemo } from 'react';
import { orderTypesSelect } from '../../utils/OrderUtils';

/**
 * props:
 * - type
 * - onChange
 */
function OrderType(props) {

    // <option value='MARKET'>Market</option>
    // <option value='TRAILING_STOP'>Trailing Stop</option>
    // <option value='LIMIT'>Limit</option>
    // <option value='STOP_LOSS_LIMIT'>Stop Loss Limit</option>
    // <option value='TAKE_PROFIT_LIMIT'>Take Profit Limit</option>
    // <option value='ICEBERG'>Iceberg</option>

    /** Binance abandonou
     * <option value='STOP_LOSS'>Stop Loss</option>
     * <option value='TAKE_PROFIT'>Take Profit</option>
     */


    const orderType = useMemo(() => (
        <div className='form-group'>
            <label htmlFor='type'>Type:</label>
            <select id='type' className='form-select' value={props.type} onChange={props.onChange}>
                {
                    Object.entries(orderTypesSelect).map(prop => (<option key={prop[0]} value={prop[0]}>{prop[1]}</option>))
                }
            </select>
        </div>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [props.type]);

    return orderType;
}

export default OrderType;