import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Pagination from '../../../components/Pagination/Pagination';
import { processError } from '../../../services/ServiceUtils';
import { getErrorMessage } from '../../../utils/MessageUtils';
import { deleteStrategy, getStrategies, startStrategy, stopStrategy } from '../../../services/StrategiesService';
import MyStrategyRow from './MyStrategyRow';
import StrategyModal from '../StrategyModal/StrategyModal';
import { findRowFromActionButton, getIdFromActionButton } from '../../../utils/DataRowButtonUtils';

/**
 * props:
 * - symbol
 * - onNotification 
 */
function MyStrategies(props) {

    const defaultLocation = useLocation();
    const history = useHistory();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        });
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(getPage());
    const [strategies, setStrategies] = useState([]);
    const [editStrategy, setEditStrategy] = useState({});

    useEffect(() => {
        getStrategies(props.symbol, page || 1)
            .then(result => {
                setStrategies(result.rows);
                setCount(result.count);
            })
            .catch(err => props.onNotification(getErrorMessage(processError(err))))
    }, [page, props.symbol]); // eslint-disable-line react-hooks/exhaustive-deps

    function onEditClick(event) {
        setEditStrategy(findRowFromActionButton(event, strategies));
    }

    function onStopClick(event) {
        stopStrategy(getIdFromActionButton(event))
            .then(strategy => { history.go(0) })
            .catch(err => props.onNotification(getErrorMessage(processError(err))))
    }

    function onStartClick(event) {
        startStrategy(getIdFromActionButton(event))
            .then(strategy => { history.go(0) })
            .catch(err => props.onNotification(getErrorMessage(processError(err))))
    }

    function onDeleteClick(event) {
        deleteStrategy(getIdFromActionButton(event))
            .then(strategy => { history.go(0) })
            .catch(err => props.onNotification(getErrorMessage(processError(err))))
    }

    function onStrategySubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th className='border-gray-200'>Symbol</th>
                            <th className='border-gray-200'>Name</th>
                            <th className='border-gray-200'>Status</th>
                            <th className='border-gray-200'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            strategies && strategies.length
                                ? strategies.map(strategy => (
                                    <MyStrategyRow key={strategy.id} data={strategy} onEditClick={onEditClick} onStopClick={onStopClick} onStartClick={onStartClick} onDeleteClick={onDeleteClick} />
                                ))
                                : <React.Fragment></React.Fragment>
                        }
                    </tbody>
                </table>
                <Pagination count={count} />
            </div>
            <StrategyModal data={editStrategy} onSubmit={onStrategySubmit} />
        </React.Fragment>
    )
}

export default MyStrategies;