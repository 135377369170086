import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { searchSymbols } from '../../services/SymbolsService';
import { processError } from '../../services/ServiceUtils';
import SymbolRow from './SymbolRow';
import SelectQuote from '../../components/SelectQuote/SelectQuote';
import { getDefaultQuote, setDefaultQuote } from '../../utils/QuoteUtils';
import SymbolModal from './SymbolModal';
import Pagination from '../../components/Pagination/Pagination';

import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { FAVORITES_QUOTE } from '../../utils/QuoteUtils';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import { findRowFromActionButton } from '../../utils/DataRowButtonUtils';

function Symbols() {

    const history = useHistory();

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page') || 1;
    }

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        })
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    const [notification, setNotification] = useState(getEmptyMessage());

    const [symbols, setSymbols] = useState([]);

    const [page, setPage] = useState(getPage());

    const [count, setCount] = useState(0);

    const [viewSymbol, setViewSymbol] = useState({
        symbol: '',
        basePrecision: 0,
        quotePrecision: 0,
        minNotional: '',
        minLotSize: ''
    });

    const [quote, setQuote] = useState(getDefaultQuote());

    function loadSymbols(selectedValue) {
        const onlyFavorites = selectedValue === FAVORITES_QUOTE;
        const search = onlyFavorites ? '' : selectedValue;
        searchSymbols(search, onlyFavorites, getPage())
            .then(result => {
                setSymbols(result.rows);
                setCount(result.count);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    useEffect(() => {
        loadSymbols(quote);
    }, [quote, page]); // eslint-disable-line react-hooks/exhaustive-deps

    function onQuoteChange(event) {
        setQuote(event.target.value);
        setDefaultQuote(event.target.value);
    }

    function onViewClick(event) {
        setViewSymbol(findRowFromActionButton(event, symbols, 'symbol', false));
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className='row py-4'>
                    <div className='col-10'>
                        <h2 className='h4'>Symbols</h2>
                    </div>
                    <div className='col-2'>
                        <SelectQuote onChange={onQuoteChange} value={quote} />
                    </div>
                </div>

                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Symbol</th>
                                <th className="border-gray-200">Base Prec</th>
                                <th className="border-gray-200">Quoted Prec</th>
                                <th className="border-gray-200">Min Notional</th>
                                <th className="border-gray-200">Min Lot Size</th>
                                <th className="border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                symbols && symbols.length
                                ? symbols.map(item => <SymbolRow key={item.symbol} data={item} onClick={onViewClick} />)
                                : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} size={20} max={15} />
                </div>

                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
            <SymbolModal data={viewSymbol} />
        </React.Fragment>)
}

export default Symbols;