const indexKeys = {
    //BEHOLDER INDICATORS
    // MINI_TICKER: 'MINI_TICKER',
    BOOK: 'BOOK',
    WALLET: 'WALLET',
    LAST_ORDER: 'LAST_ORDER',
    LAST_CANDLE: 'LAST_CANDLE',
    TICKER: 'TICKER'
}

module.exports = {
    indexKeys
}