import React from 'react';
import RowDeleteButton from '../../components/ActionButtons/RowDeleteButton';
import RowEditButton from '../../components/ActionButtons/RowEditButton';
import RowLogButton from '../../components/ActionButtons/RowLogButton';
import RowStartButton from '../../components/ActionButtons/RowStartButton';
import RowStopButton from '../../components/ActionButtons/RowStopButton';
import MonitorIcon from '../../components/MonitorIcon/MonitorIcon';

/**
 * props:
 * - data
 * - onEditClick
 * - onStopClick
 * - onStartClick
 * - onDeleteClick
 * - onLogsClick
 */
function MonitorRow(props) {

    function getStatusClass(isActive) {
        return isActive ? 'text-success' : 'text-danger';
    }

    function getStatusText(isActive) {
        return isActive ? 'RUNNING' : 'STOPPED';
    }

    return (
        <tr>
            <td>
                <MonitorIcon data={props.data} />
                {props.data.type}
            </td>
            <td>
                {
                    props.data.symbol
                        ? props.data.symbol
                        : '*'
                }
                {
                    props.data.interval
                        ? `_${props.data.interval}`
                        : ''
                }
            </td>
            <td>
                <span className={getStatusClass(props.data.isActive)}>
                    {getStatusText(props.data.isActive)}
                </span>
            </td>
            <td>
                <RowEditButton id={props.data.id} title='Edit this Monitor' modalId='modalMonitor' onClick={props.onEditClick} showButton={!props.data.isSystemMon} />
                <RowStopButton id={props.data.id} title='Stop this Monitor' onClick={props.onStopClick} showButton={props.data.isActive && !props.data.isSystemMon} />
                <RowStartButton id={props.data.id} title='Start this Monitor' onClick={props.onStartClick} showButton={!props.data.isActive && !props.data.isSystemMon} />
                <RowDeleteButton id={props.data.id} title='Delete this Monitor' onClick={props.onDeleteClick} showButton={!props.data.isActive && !props.data.isSystemMon} />
                <RowLogButton id={props.data.id} title='Logs from this Monitor' onClick={props.onLogsClick} showButton={props.data.isSystemMon} />
            </td>
        </tr>
    );
}

export default MonitorRow;