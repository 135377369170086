import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../components/Pagination/Pagination';
import Footer from '../../components/Footer/Footer';
import { getMonitors, deleteMonitor, startMonitor, stopMonitor } from '../../services/MonitorsService';
import { processError } from '../../services/ServiceUtils';
import MonitorRow from './MonitorRow';
import MonitorModal from './MonitorModal/MonitorModal';
import Toast from '../../components/Toast/Toast';
import LogModal from '../../components/Logs/LogModal';
import NewMonitorButton from './NewMonitorButton';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import { findRowFromActionButton, getIdFromActionButton } from '../../utils/DataRowButtonUtils';

function Monitors() {

    const defaultLocation = useLocation();
    const history = useHistory();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        });
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(getPage());
    const [monitors, setMonitors] = useState([]);

    const [notification, setNotification] = useState(getEmptyMessage());

    const [editMonitor, setEditMonitor] = useState({});

    useEffect(() => {
        getMonitors(page || 1)
            .then(result => {
                setMonitors(result.rows);
                setCount(result.count);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, [page])

    function onEditClick(event) {
        setEditMonitor(findRowFromActionButton(event, monitors));
    }

    function onLogsClick(event) {
        setEditMonitor(findRowFromActionButton(event, monitors));
    }

    function onStopClick(event) {
        stopMonitor(getIdFromActionButton(event))
            .then(monitor => { history.go(0) })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onStartClick(event) {
        startMonitor(getIdFromActionButton(event))
            .then(monitor => { history.go(0) })
            .catch(err => setNotification(getErrorMessage(processError(err))))
}

    function onDeleteClick(event) {
        deleteMonitor(getIdFromActionButton(event))
            .then(monitor => { history.go(0) })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onModalSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Monitors</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <NewMonitorButton />
                        </div>
                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Type</th>
                                <th className='border-gray-200'>Symbol</th>
                                <th className='border-gray-200'>Status</th>
                                <th className='border-gray-200'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                monitors && monitors.length
                                ? monitors.map(monitor => (
                                    <MonitorRow key={monitor.id} data={monitor} onEditClick={onEditClick} onStopClick={onStopClick} onStartClick={onStartClick} onDeleteClick={onDeleteClick} onLogsClick={onLogsClick} />
                                ))
                                : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <MonitorModal data={editMonitor} onSubmit={onModalSubmit} />
            <LogModal file={editMonitor.id > 0 ? 'M_' + editMonitor.id : ''} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default Monitors;