import React, { useState, useEffect } from 'react';
// import useWebSocket from 'react-use-websocket';
// import { processWebSocketError } from '../../utils/MessageUtils';
import { formatNumber } from '../../utils/NumberUtils';
import { DEFAULT_SYMBOL } from '../../utils/SymbolUtils';
import BinanceWebSocket from '../BinanceWebSocket/BinanceWebSocket';

/**
 * props:
 * - symbol
 * - onChange
 */
function SymbolPrice(props) {

    // if (!props.symbol) return <React.Fragment></React.Fragment>;

    const [symbol, setSymbol] = useState(DEFAULT_SYMBOL);
    const [book, setBook] = useState({ bid: '0', ask: '0'});

    useEffect(() => {
        if (!props.symbol) return;
        setSymbol(props.symbol);
    }, [props.symbol]);

    function onBinanceMessage(b) {
        setBook(b);
        if (props.onChange) props.onChange(b);
    }

    /*
    function getBinanceWsUrl() {
        return `${process.env.REACT_APP_BWS_URL}/${symbol.toLowerCase()}@bookTicker`;
    }

    const { lastJsonMessage, sendJsonMessage } = useWebSocket(getBinanceWsUrl(), {
        onOpen: () => {
            console.log(`Connected to Binance Stream ${symbol}`);
            sendJsonMessage({
                method: "SUBSCRIBE",
                params: [`${symbol.toLowerCase()}@bookTicker`],
                id: 1
            })    
        },
        onMessage: () => {
            if (lastJsonMessage) {
                const b = { bid: lastJsonMessage.b, ask: lastJsonMessage.a };
                setBook(b);
                // console.log('bid='+book.bid);
                // console.log('ask='+book.ask);
                if (props.onChange) props.onChange(b);
            }
        },
        onError: (event) => processWebSocketError(event),
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
    });
    */

    return (
        <div className='form-group'>
            <label>Market Price</label><br />
            BID: {formatNumber(book.bid)}<br />
            ASK: {formatNumber(book.ask)}
            <BinanceWebSocket symbol={symbol} serviceName={'bookTicker'} onMessage={onBinanceMessage} />
        </div>
    )
}

export default SymbolPrice;