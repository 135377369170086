import React, { useRef, useState, useEffect } from 'react';
import ActionMessage from '../../../components/ActionMessage/ActionMessage';
import SelectSymbol from '../../../components/SelectSymbol/SelectSymbol';
import LogButton from '../../../components/Logs/LogButton';
import LogView from '../../../components/Logs/LogView';
import AutomationsList from './AutomationsList';
import { doBacktest, getAllAutomations } from '../../../services/AutomationsService';
import { processError } from '../../../services/ServiceUtils';
import { isGridAutomation, isScheduleAutomation } from '../../../utils/AutomationUtils';
import { DEFAULT_SYMBOL } from '../../../utils/SymbolUtils';
import { getSymbol } from '../../../services/SymbolsService';
import { getMemoryIndex } from '../../../services/BeholderService';
import { indexKeys } from '../../../utils/IndexUtils';
import DateTime from '../AutomationModal/ScheduleArea/DateTime';
import ResultsTable from './ResultsTable';
import ResultsButton from './ResultsButton';
import { getValueOrDefault, initializeModal } from '../../../utils/ModalUtils';
import SelectStrategy from '../../../components/SelectStrategy/SelectStrategy';
import { formatNumber } from '../../../utils/NumberUtils';
import { getToday, getYesterday } from '../../../utils/DateUtils';

/**
 * props:
 * - showStrategies
 */
function BacktestModal(props) {
    const DEFAULT_BACKTEST = {
        symbol: DEFAULT_SYMBOL,
        base: '',
        quote: '',
        startBase: 0,
        startQuote: 0,
        startTime: new Date(getYesterday()),
        endTime: new Date(getToday()),
        automationIds: [],
        strategyId: null
    };

    const [backtest, setBacktest] = useState(DEFAULT_BACKTEST);
    const [automations, setAutomations] = useState([]);

    const [testing, setTesting] = useState(false);
    const [error, setError] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [showLogs, setShowLogs] = useState(false);

    const btnClose = useRef('');
    const btnTest = useRef('');

    function adjustBacktestObject(result) {
        result.automationIds = backtest.automationIds;
        result.strategyId = backtest.strategyId;
        result.base = backtest.base;
        result.quote = backtest.quote;
        result.startTime = backtest.startTime;
        result.endTime = backtest.endTime;
        result.startBase = parseFloat(result.startBase);
        result.startQuote = parseFloat(result.startQuote);
        result.endBase = parseFloat(result.endBase);
        result.endQuote = parseFloat(result.endQuote);
        result.basePerc = parseFloat(result.basePerc);
        result.quotePerc = parseFloat(result.quotePerc);

        result.quotePerc = parseFloat(result.quotePerc);

        result.startQtyUsd = parseFloat(result.startQtyUsd);
        result.endQtyUsd = parseFloat(result.endQtyUsd);
        result.profitUsd = parseFloat(result.profitUsd);
        result.profitPerc = parseFloat(result.profitPerc);
        // console.log(result);

        return result;
    }

    function onSubmit(event) {
        if (testing) return;

        setError('');
        setTesting(true);
        doBacktest(backtest)
            .then(result => {
                result = adjustBacktestObject(result);
                // console.log(result);
                setBacktest(result);
                setTesting(false);
                setShowLogs(false);
                setShowResults(true);
            })
            .catch(err => {
                setError(processError(err));
                setTesting(false);
            });
    }

    function onInputChange(event) {
        setBacktest(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function updateSymbol(newSymbol) {
        const newBacktest = { ...DEFAULT_BACKTEST };
        newBacktest.symbol = newSymbol;

        let symbol;
        getSymbol(newBacktest.symbol)
            .then(symbolObj => {
                symbol = symbolObj;
                newBacktest.base = symbol.base;
                newBacktest.quote = symbol.quote;
                return getMemoryIndex(symbol.base, indexKeys.WALLET, null);
            })
            .then(base => {
                newBacktest.startBase = base;
                return getMemoryIndex(symbol.quote, indexKeys.WALLET, null);
            })
            .then(quote => {
                newBacktest.startQuote = quote;
                setBacktest({ ...newBacktest });
            })
            .catch(err => setError(processError(err)));
    }

    // function onSymbolChange(event) {
    //     if (!event.target.value) return;

    //     updateSymbol(event.target.value);
    // }

    useEffect(() => {
        updateSymbol(backtest.symbol);

        getAllAutomations(backtest.symbol, null, false)
            .then(results => {
                results = results.filter(a => !isScheduleAutomation(a) && !isGridAutomation(a));
                setAutomations(results);
                setShowLogs(false);
                setShowResults(false);
            })
            .catch(err => setError(processError(err)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backtest.symbol]);

    function onLogClick(event) {
        if (!showLogs) setShowResults(false);
        setShowLogs(!showLogs);
    }

    function onViewResultsClick(event) {
        if (!showResults) setShowLogs(false);
        setShowResults(!showResults);
    }

    function formIsFilled() {
        return (
            backtest.automationIds.length > 0
            && backtest.startBase
            && backtest.startQuote
            && backtest.startTime
            && backtest.endTime
        );
    }

    function initializeStates() {
        const newBacktest = { ...DEFAULT_BACKTEST }; 
        setBacktest(newBacktest);
        updateSymbol(newBacktest.symbol);
        setTesting(false);
        setError('');
        setAutomations([]);
        setShowResults(false);
        setShowLogs(false);
    }

    useEffect(() => {
        initializeModal('modalBacktest', () => initializeStates());
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <div className="modal fade" id="modalBacktest" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">Backtest</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label htmlFor='symbol'>Symbol</label>
                                        <SelectSymbol symbol={backtest.symbol} onChange={onInputChange} />
                                    </div>
                                </div>
                                {
                                    backtest.id
                                        ? <div className='col-md-6 mb-3'>
                                            <div className="row">
                                                <label className='col-6'>Start Qty (USD):</label>
                                                <span className='col-6'>{formatNumber(backtest.startQtyUsd,2)}</span>
                                            </div>
                                            <div className="row">
                                                <label className='col-6'>End Qty (USD):</label>
                                                <span className='col-6'>{formatNumber(backtest.endQtyUsd,2)}</span>
                                            </div>
                                            <div className="row">
                                                <label className='col-6'>Profit (USD):</label>
                                                <span className='col-6'>{formatNumber(backtest.profitUsd,2)}</span>
                                            </div>
                                            <div className="row">
                                                <label className='col-6'>Profit (%):</label>
                                                <span className='col-6'>{formatNumber(backtest.profitPerc,2)}</span>
                                            </div>
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                            {
                                !showLogs && !showResults
                                    ? (
                                        <React.Fragment>
                                            {
                                                props.showStrategies
                                                    ? <div className='row'>
                                                        <div className='col-6 mb-3'>
                                                            <label>Strategy:</label>
                                                            <SelectStrategy symbol={backtest.symbol} strategyId={backtest.strategyId} onlyActives={false} onChange={onInputChange} />
                                                        </div>
                                                    </div>
                                                    : <AutomationsList automationIds={backtest.automationIds} data={automations} onChange={onInputChange} />
                                            }
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor='startBase'>Start Base:</label>
                                                        <input type='number' id='startBase' className='form-control' placeholder='0' value={getValueOrDefault(backtest.startBase)} onChange={onInputChange} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor='startQuote'>Start Quote:</label>
                                                        <input type='number' id='startQuote' className='form-control' placeholder='0' value={getValueOrDefault(backtest.startQuote)} onChange={onInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor='startTime'>Start Date:</label>
                                                        <DateTime id="startTime" onChange={onInputChange} date={getValueOrDefault(backtest.startTime)} />
                                                    </div>
                                                </div>
                                                <div className='col-6 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor='endTime'>End Date:</label>
                                                        <DateTime id="endTime" onChange={onInputChange} date={getValueOrDefault(backtest.endTime)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                    : <React.Fragment></React.Fragment>
                            }
                            {
                                !showLogs && showResults
                                    ? <ResultsTable data={backtest} />
                                    : <React.Fragment></React.Fragment>
                            }
                            {
                                showLogs && !showResults
                                    ? <LogView file={'backtest-' + backtest.userId} />
                                    : (
                                        <React.Fragment>
                                        </React.Fragment>
                                    )
                            }

                        </div>

                    </div>

                    <div className="modal-footer">
                        <ActionMessage error={error} extraClassName="mt-1 col-9 py-1" />
                        <LogButton id={backtest.id} onClick={onLogClick} />
                        <ResultsButton id={backtest.id} onClick={onViewResultsClick} />
                        <button ref={btnTest} type="button" className="btn btn-sm btn-primary" onClick={onSubmit} disabled={testing || (!formIsFilled())}>
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" /></svg>
                            {
                                testing
                                    ? 'Testing...'
                                    : 'Start Test'
                            }
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BacktestModal;