const monitorTypes = {
    BOOK: 'BOOK', // Book
    // MINI_TICKER: 'MINI_TICKER', // Mini-Ticker
    USER_DATA: 'USER_DATA', // User Data

    CANDLES: 'CANDLES',
    TICKER: 'TICKER'
}

const monitorTypesSelect = {
    CANDLES: 'Candles'
    // ,TICKER: 'Ticker'
}

module.exports = {
    monitorTypes,
    monitorTypesSelect
}