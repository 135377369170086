import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../components/Pagination/Pagination';
import Footer from '../../components/Footer/Footer';
import { processError } from '../../services/ServiceUtils';
import WebHookRow from './WebHookRow';
import WebHookModal from './WebHookModal';
import Toast from '../../components/Toast/Toast';
import NewWebHookButton from './NewWebHookButton';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import { deleteWebHook, getWebHooks } from '../../services/WebHooksService';
import { findRowFromActionButton, getIdFromActionButton } from '../../utils/DataRowButtonUtils';

function WebHooks() {

    const defaultLocation = useLocation();
    const history = useHistory();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        });
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(getPage());
    const [webHooks, setWebHooks] = useState([]);

    const [notification, setNotification] = useState(getEmptyMessage());

    const [editWebHook, setEditWebHook] = useState({});

    useEffect(() => {
        getWebHooks(page || 1)
            .then(result => {
                setWebHooks(result.rows);
                setCount(result.count);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, [page]);

    function onEditClick(event) {
        setEditWebHook(findRowFromActionButton(event, webHooks));
    }

    function onDeleteClick(event) {
        deleteWebHook(getIdFromActionButton(event))
            .then(result => { history.go(0) })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onModalSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>WebHooks</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <NewWebHookButton />
                        </div>
                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Symbol</th>
                                <th className='border-gray-200'>Name</th>
                                <th className='border-gray-200'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                webHooks && webHooks.length
                                    ? webHooks.map(webHook => (
                                        <WebHookRow key={webHook.id} data={webHook} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
                                    ))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <WebHookModal data={editWebHook} onSubmit={onModalSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default WebHooks;