import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import { processError } from '../../services/ServiceUtils';
import WalletRow from './WalletRow';
import Toast from '../../components/Toast/Toast';
import { getEmptyMessage, getErrorMessage } from '../../utils/MessageUtils';
import { getFullBalance } from '../../services/ExchangeService';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import SelectFiat from '../../components/SelectFiat/SelectFiat';
import { setDefaultFiat, getDefaultFiat } from '../../utils/FiatUtils';

function Wallet() {

    const history = useHistory();
    const [balances, setBalances] = useState([]);
    const [total, setTotal] = useState('');
    const [notification, setNotification] = useState(getEmptyMessage());
    const [fiat, setFiat] = useState(getDefaultFiat());

    useEffect(() => {
        getFullBalance(fiat)
            .then(info => {
                setTotal(info.fiatEstimate);
                delete (info.fiatEstimate);

                const balances = Object.entries(info).map(item => {
                    return {
                        symbol: item[0],
                        available: item[1].available,
                        onOrder: item[1].onOrder,
                        fiatEstimate: item[1].fiatEstimate,
                        avg: item[1].avg
                    }
                });
                setBalances(balances);
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, [fiat]);

    function onOrderSubmit(event) {
        history.go(0);
    }

    function onFiatChange(event) {
        const selectedFiat = event.target.value;
        setDefaultFiat(selectedFiat);
        setFiat(selectedFiat);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Wallet</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <div className='me-2'>
                                <SelectFiat onChange={onFiatChange} />
                            </div>
                            <NewOrderButton />
                        </div>
                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Coin</th>
                                <th className='border-gray-200 text-center'>Free</th>
                                <th className='border-gray-200 text-center'>Locked</th>
                                <th className='border-gray-200 text-end'>Fiat Total</th>
                                <th className='border-gray-200 text-end'>Avg Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                balances && balances.length
                                    ? balances.filter(b => parseFloat(b.available) > 0 || parseFloat(b.onOrder) > 0).map(item => (
                                        <WalletRow key={item.symbol} data={item} />
                                    ))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='text-end'>{total}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
            <NewOrderModal onSubmit={onOrderSubmit} />
        </React.Fragment>
    )
}

export default Wallet;