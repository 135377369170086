import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';
import { checkFloatValue } from '../utils/NumberUtils';

const WITHDRAW_TEMPLATES_URL = getBaseUrl('/withdrawTemplates/');

export async function getWithdrawTemplates(coin, page) {
    return await appRequest.doGetRequest(`${WITHDRAW_TEMPLATES_URL}${coin}?page=${page}`);
}

export async function getAllWithdrawTemplates(coin) {
    return await appRequest.doGetRequest(`${WITHDRAW_TEMPLATES_URL}all/${coin}`);
}

export async function saveWithdrawTemplate(id, newWithdrawTemplate) {
    newWithdrawTemplate.amountMultiplier = checkFloatValue(newWithdrawTemplate.amountMultiplier);
    if (id)
        return await appRequest.doPatchRequest(`${WITHDRAW_TEMPLATES_URL}${id}`, newWithdrawTemplate);
    else
        return await appRequest.doPostRequest(WITHDRAW_TEMPLATES_URL, newWithdrawTemplate);
}

export async function deleteWithdrawTemplate(id) {
    return await appRequest.doDeleteRequest(`${WITHDRAW_TEMPLATES_URL}${id}`);
}

