import React, { useState, useEffect, useRef } from 'react';
import { getEventObject } from '../../../utils/EventUtils';
import { getValueOrDefault } from '../../../utils/ModalUtils';
import { quantityTypeNames, getQuantityValue, getQuantityName } from '../../../utils/QuantityUtils';

/**
 * props:
 * - id
 * - text
 * - quantity
 * - multiplier
 * - onChange
 */
function QuantityTemplate(props) {

    const [quantityTemplate, setQuantityTemplate] = useState({ quantity: '', multiplier: '' });

    const quantityRef = useRef('');

    function onQuantityChange(event) {
        let value = event.target.value;
        /*
        if (value === 'Max. Wallet')
            value = 'MAX_WALLET';
        else if (value === 'Min. Notional')
            value = 'MIN_NOTIONAL';
        else if (value === 'Last Order Qty.')
            value = 'LAST_ORDER_QTY';
        */
        value = getQuantityValue(value);

        props.onChange(getEventObject(props.id, value));
    }

    useEffect(() => {
        if (props.quantity === null || props.quantity === undefined) return;

        let qty = props.quantity;
        /*
        if (props.quantity === 'MAX_WALLET')
            qty = 'Max. Wallet';
        else if (props.quantity === 'MIN_NOTIONAL')
            qty = 'Min. Notional';
        else if (props.quantity === 'LAST_ORDER_QTY')
            qty = 'Last Order Qty.';
        */
        qty = getQuantityName(qty);

        setQuantityTemplate({ quantity: qty, multiplier: props.multiplier });
    }, [props.quantity, props.multiplier]);

    function clearQuantity(event) {
        quantityTemplate.quantity = '';
        quantityRef.current.value = '';
    }

    return (
        <div className='form-group'>
            <label htmlFor={props.id}>{props.text} <span data-bs-toggle="tootip" data-bs-placement='top' title='Max. Wallet trades the maximum you have. Min. Notional trades the minimum allowed. Multiplying by 1 = 100%.' className='badge bg-warning py-1'>?</span></label>
            <div className='input-group'>
                <input ref={quantityRef} type='text' id={props.id} list='qtyOptions' className='form-control w-50' onChange={onQuantityChange} placeholder='0' value={getValueOrDefault(quantityTemplate.quantity)} />
                {
                    quantityTemplate.quantity !== quantityTypeNames.QUOTE_QTY
                        ? (
                            <span className='input-group-text bg-secondary' onClick={clearQuantity} title='Click to clear quantity'>X</span>
                        )
                        : <React.Fragment></React.Fragment>
                }
                <input id={props.id + 'Multiplier'} type='number' className='form-control' onChange={props.onChange} placeholder='1' value={getValueOrDefault(quantityTemplate.multiplier)} />
                <datalist id='qtyOptions'>
                    <option>{quantityTypeNames.LAST_ORDER_QTY}</option>
                    <option>{quantityTypeNames.MAX_WALLET}</option>
                    <option>{quantityTypeNames.MIN_NOTIONAL}</option>
                    <option>{quantityTypeNames.QUOTE_QTY}</option>
                </datalist>
            </div>
        </div>
    )
}

export default QuantityTemplate;