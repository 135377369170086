import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Pagination from '../../components/Pagination/Pagination';
import Footer from '../../components/Footer/Footer';
import { processError } from '../../services/ServiceUtils';
import WithdrawTemplateRow from './WithdrawTemplateRow';
import WithdrawTemplateModal from './WithdrawTemplateModal/WithdrawTemplateModal';
import Toast from '../../components/Toast/Toast';
import NewWithdrawTemplateButton from './NewWithdrawTemplateButton';
import { getWithdrawTemplates, deleteWithdrawTemplate } from '../../services/WithdrawTemplatesService';
import { doWithdraw } from '../../services/ExchangeService';
import { getEmptyMessage, getErrorMessage, getInfoMessage, getSuccessMessage } from '../../utils/MessageUtils';
import { isRunningLocalhost } from '../../utils/NetUtils';
import { findRowFromActionButton, getIdFromActionButton } from '../../utils/DataRowButtonUtils';

function WithdrawTemplates() {

    useEffect(() => {        
        if (isRunningLocalhost())
            setNotification(getInfoMessage('These features only work in production.'));
    }, []);

    const defaultLocation = useLocation();

    const history = useHistory();

    const [withdrawTemplates, setWithdrawTemplates] = useState([]);
    const [page, setPage] = useState(getPage());
    const [count, setCount] = useState(0);
    const [notification, setNotification] = useState(getEmptyMessage());

    const [editWithdrawTemplate, setEditWithdrawTemplate] = useState({});

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen(location => setPage(getPage(location)));
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getWithdrawTemplates('', page || 1)
            .then(result => {
                setWithdrawTemplates(result.rows ? result.rows : []);
                setCount(result.count);
                setEditWithdrawTemplate(result.rows && result.rows.length > 0 ? result.rows[0] : {});
            })
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }, [page]);

    function onEditClick(event) {
        setEditWithdrawTemplate(findRowFromActionButton(event, withdrawTemplates));
    }

    function onDeleteClick(event) {
        deleteWithdrawTemplate(getIdFromActionButton(event))
            .then(result => history.go(0))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onRunClick(event) {
        doWithdraw(getIdFromActionButton(event))
            .then(result => setNotification(getSuccessMessage(`Withdraw #${result.id} successful!`)))
            .catch(err => setNotification(getErrorMessage(processError(err))))
    }

    function onSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className='content'>
                <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
                    <div className='d-block mb-4 mb-md-0'>
                        <h2 className='h4'>Withdraw Templates</h2>
                    </div>
                    <div className='btn-toolbar mb-2 mb-md-0'>
                        <div className='d-inline-flex align-items-center'>
                            <NewWithdrawTemplateButton />
                        </div>
                    </div>
                </div>
                <div className='card card-body border-0 shadow table-wrapper table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th className='border-gray-200'>Coin</th>
                                <th className='border-gray-200'>Name</th>
                                <th className='border-gray-200'>Amount</th>
                                <th className='border-gray-200'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                withdrawTemplates && withdrawTemplates.length
                                    ? withdrawTemplates.map(ot => (<WithdrawTemplateRow key={ot.id} data={ot} onEditClick={onEditClick} onDeleteClick={onDeleteClick} onRunClick={onRunClick} />))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <WithdrawTemplateModal data={editWithdrawTemplate} onSubmit={onSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default WithdrawTemplates;