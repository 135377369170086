import { getBaseUrl } from './ServiceUtils';
import appRequest from './appRequest';

const WEBHOOKS_URL = getBaseUrl('/webhooks/');

export async function getWebHooks(page) {
    return await appRequest.doGetRequest(`${WEBHOOKS_URL}?page=${page}`);
}

export async function saveWebHook(id, newWebHook) {
    if (id)
        return await appRequest.doPatchRequest(`${WEBHOOKS_URL}${id}`, newWebHook);
    else
        return await appRequest.doPostRequest(WEBHOOKS_URL, newWebHook);
}

export async function deleteWebHook(id) {
    return await appRequest.doDeleteRequest(`${WEBHOOKS_URL}${id}`);
}